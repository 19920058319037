import { op } from "libs/transaction";
import { navigateService } from "~/environment/navigate.service";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { withTransaction, write } from "./write";

export const toggleFocusMode = onlyCallFnOnceWhilePreviousCallIsPending(
  withTransaction(
    "toggleFocusMode",
    async (
      environment,
      transaction,
      props: {
        enableFocusMode: boolean;
      },
    ) => {
      const currentUserId = environment.auth.getAndAssertCurrentUserId();

      const [settingsRec] = await environment.recordLoader.getRecord({
        table: "user_settings",
        id: currentUserId,
      });

      const hasTheUserEverEnabledFocusModeBefore = !!settingsRec?.settings.focus_mode_exceptions;

      if (hasTheUserEverEnabledFocusModeBefore) {
        transaction.operations.push(
          op.set(
            { table: "user_settings", id: currentUserId },
            ["settings", "enable_focus_mode"],
            props.enableFocusMode,
          ),
        );
      } else {
        // If the user has never enabled focus mode before,
        // we take them to the focus mode section of the
        // settings so that they can learn more about it
        // and do any initial setup that's necessary.
        navigateService("/settings#focus-mode");
        return;
      }

      await write(environment, { transaction });
    },
  ),
);
