import { UnreachableCaseError } from "libs/errors";
import { getPointer, TagSubscriptionPreference } from "libs/schema";
import { op } from "libs/transaction";
import { toast } from "~/environment/toast-service";
import { withTransaction, write } from "./write";

export const updateTagSubscription = withTransaction(
  "updateTagSubscription",
  async (
    environment,
    transaction,
    props: {
      tagId: string;
      preference: TagSubscriptionPreference;
    },
  ) => {
    const currentUserId = environment.auth.getAndAssertCurrentUserId();
    const ownerOrganizationId = environment.auth.getAndAssertCurrentUserOwnerOrganizationId();

    showToastForTagSubscriptionChange(props.preference);

    const pointer = getPointer("tag_subscription", {
      tag_id: props.tagId,
      user_id: currentUserId,
    });

    transaction.operations.push(
      op.set("tag_subscription", {
        id: pointer.id,
        creator_user_id: currentUserId,
        owner_organization_id: ownerOrganizationId,
        preference: props.preference,
        tag_id: props.tagId,
        user_id: currentUserId,
      }),
    );

    await write(environment, {
      transaction,
      onOptimisticUndo: () => {
        toast("vanilla", { subject: "Undoing tag subscription update." });
      },
    });
  },
);

function showToastForTagSubscriptionChange(preference: TagSubscriptionPreference) {
  switch (preference) {
    case "all": {
      toast("vanilla", {
        subject: `Subscribed to All.`,
        description: `
          You will receive a notification for every message 
          in this channel.
        `,
      });
      break;
    }
    case "all-new": {
      toast("vanilla", {
        subject: `Subscribed.`,
        description: `
          You will receive a notification for every new
          thread created in this channel.
        `,
      });
      break;
    }
    case "involved": {
      toast("vanilla", {
        subject: `Unsubscribed.`,
        description: `
          You will only receive notifications for 
          threads you are participating or @mentioned in.
        `,
      });
      break;
    }
    default: {
      throw new UnreachableCaseError(preference);
    }
  }
}
