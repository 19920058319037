import { PointerWithRecord, RecordPointer, RecordTable } from "libs/schema";
import { of, switchMap } from "rxjs";
import { startWith } from "libs/rxjs-operators";
import { useLoadingObservable } from "./useLoadingObservable";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { ObserveOptions } from "~/environment/RecordLoader";
import { useDistinctUntilChanged } from "./useDistinctUntilChanged";
import { triggerPendingRequestBarOperator } from "~/components/PendingRequestBar";

export type UseRecordsResult<T extends RecordTable> = [PointerWithRecord<T>[], { isLoading: boolean }];

export type UseRecordsOptions = {
  /** Hook name. Used for debugging purposes. */
  name?: string;
};

export function useRecords<T extends RecordTable>(
  pointers: RecordPointer<T>[],
  options?: UseRecordsOptions,
): UseRecordsResult<T> {
  const { recordLoader } = useClientEnvironment();

  const stablePointers = useDistinctUntilChanged(pointers);

  const result = useLoadingObservable({
    initialValue: DEFAULT_VALUE as UseRecordsResult<T>,
    deps: [recordLoader, stablePointers, options?.name],
    fn(inputs$) {
      return inputs$.pipe(
        switchMap(([recordLoader, pointers, name]) => {
          if (!pointers.length) {
            return of<UseRecordsResult<T>>([[], { isLoading: false }]);
          }

          const options: ObserveOptions = {};

          return recordLoader.observeGetRecords(pointers, options).pipe(
            startWith(() => [[], { isLoading: true }] as UseRecordsResult<T>),
            triggerPendingRequestBarOperator(name),
          );
        }),
      );
    },
  });

  return result;
}

const DEFAULT_VALUE = Object.freeze([
  Object.freeze([]) as unknown as PointerWithRecord[],
  Object.freeze({ isLoading: true }),
]) as UseRecordsResult<any>;
