import { isEqual } from "libs/predicates";
import { createRecordMapFromPointersWithRecords } from "libs/schema";
import { applyTransaction, getOperationPointers, invertOperation, op, Operation, Transaction } from "libs/transaction";
import { uniqWith, pick } from "lodash-comms";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { withPendingUpdate } from "~/environment/loading.service";

/* -------------------------------------------------------------------------------------------------
 *  withTransaction
 * -------------------------------------------------------------------------------------------------
 */

export function withTransaction(
  label: string,
  fn: (environment: ClientEnvironment, transaction: Transaction, props: undefined) => Promise<void>,
): (environment: ClientEnvironment) => Promise<void>;
export function withTransaction<T extends Record<string, any> | undefined>(
  label: string,
  fn: (environment: ClientEnvironment, transaction: Transaction, props: T) => Promise<void>,
): (environment: ClientEnvironment, props: T) => Promise<void>;
export function withTransaction<T extends Record<string, any> | undefined = undefined>(
  label: string,
  fn: (environment: ClientEnvironment, transaction: Transaction, props: T) => Promise<void>,
) {
  return withPendingUpdate((environment: ClientEnvironment, props: T) => {
    const currentUserId = environment.auth.getAndAssertCurrentUserId();

    const transaction = op.transaction({
      authorId: currentUserId,
      label,
    });

    const logger = createTxLogger(environment, { transaction, data: props });

    return fn({ ...environment, logger }, transaction, props);
  });
}

/* -------------------------------------------------------------------------------------------------
 *  write
 * -------------------------------------------------------------------------------------------------
 */

export async function write(
  environment: ClientEnvironment,
  props: {
    transaction: Transaction;
    canUndo?: boolean | ((props: { isRedo: boolean }) => boolean);
    onOptimisticUndo?: () => void | Promise<void>;
    onServerUndo?: () => void | Promise<void>;
    onOptimisticWrite?: (props: { isRedo: boolean }) => void | Promise<void>;
    onServerWrite?: (props: { isRedo: boolean }) => void | Promise<void>;
  },
) {
  const { undoRedo } = environment;
  const { transaction, canUndo = true } = props;

  const undoTransaction = invertTransaction(environment, transaction);

  if (undoTransaction && canUndo) {
    // Currently we only support undoing the most recent change. This is a user experience decision, as allowing undoing
    // more transactions can get confusing if you're undoing an action which was taken on a previous page.
    undoRedo.undoStack = [
      {
        transaction: undoTransaction,
        canUndo: typeof canUndo === "function" ? canUndo : undefined,
        onOptimisticUndo: props.onOptimisticUndo,
        onServerUndo: props.onServerUndo,
        onOptimisticRedo: props.onOptimisticWrite,
        onServerRedo: props.onServerWrite,
      },
    ];

    undoRedo.redoStack = [];
    undoRedo.resetUndoWindow();
  }

  optimisticWrite(environment, transaction);

  props.onOptimisticWrite?.({ isRedo: false });

  return enqueueTransaction(environment, transaction).then(() => props.onServerWrite?.({ isRedo: false }));
}

/* -------------------------------------------------------------------------------------------------
 *  undo
 * -------------------------------------------------------------------------------------------------
 */

export async function undo(environment: ClientEnvironment) {
  const { undoRedo } = environment;

  if (undoRedo.running) return;

  const undoEntry = undoRedo.undoStack.pop();
  if (!undoEntry) return;

  try {
    undoRedo.running = true;

    const { transaction, canUndo } = undoEntry;

    if (canUndo && !(await canUndo({ isRedo: false }))) {
      return;
    }

    // It's possible that the records used by the original transaction have been garbage
    // collected from memory so we refetch them here.
    const pointers = uniqWith(transaction.operations.flatMap(getOperationPointers), isEqual);
    await environment.recordLoader.getRecords(pointers, { includeSoftDeletes: true, fetchStrategy: "cache-first" });

    // Optimistic update.
    const redoTransaction = invertTransaction(environment, transaction);
    if (!redoTransaction) throw new Error("Undo transactions should always be invertible.");
    undoRedo.redoStack.push({ ...undoEntry, transaction: redoTransaction });
    undoRedo.resetUndoWindow();

    optimisticWrite(environment, transaction);
    undoEntry.onOptimisticUndo?.();

    await enqueueTransaction(environment, transaction);
    await undoEntry.onServerUndo?.();
  } finally {
    undoRedo.running = false;
  }
}

/* -------------------------------------------------------------------------------------------------
 *  redo
 * -------------------------------------------------------------------------------------------------
 */

export async function redo(environment: ClientEnvironment) {
  const { undoRedo } = environment;

  if (undoRedo.running) return;

  const redoEntry = undoRedo.redoStack.pop();
  if (!redoEntry) return;

  try {
    undoRedo.running = true;

    const { transaction, canUndo } = redoEntry;

    if (canUndo && !(await canUndo({ isRedo: true }))) {
      return;
    }

    // It's possible that the records used by the original transaction have been garbage
    // collected from memory so we refetch them here.
    const pointers = uniqWith(transaction.operations.flatMap(getOperationPointers), isEqual);
    await environment.recordLoader.getRecords(pointers, { includeSoftDeletes: true, fetchStrategy: "cache-first" });

    // Optimistic update.
    const undoTransaction = invertTransaction(environment, transaction);
    if (!undoTransaction) throw new Error("Redo transactions should always be invertible.");
    undoRedo.undoStack.push({ ...redoEntry, transaction: undoTransaction });
    undoRedo.resetUndoWindow();

    optimisticWrite(environment, transaction);
    redoEntry.onOptimisticRedo?.({ isRedo: true });

    await enqueueTransaction(environment, transaction);
    await redoEntry.onServerRedo?.({ isRedo: true });
  } finally {
    undoRedo.running = false;
  }
}

/* -------------------------------------------------------------------------------------------------
 *  createTxLogger
 * -------------------------------------------------------------------------------------------------
 */

export function createTxLogger(
  environment: Pick<ClientEnvironment, "logger">,
  props?: {
    transaction?: Transaction;
    data?: Record<string, any> | Record<string, any>[];
  },
) {
  const data = Array.isArray(props?.data) ? props?.data.reduce(Object.assign, {}) : props?.data;

  const bindings = {
    ...data,
    transaction: pick(props?.transaction, ["txId", "label"]),
  };

  if (props?.transaction?.label) {
    bindings.name = props.transaction.label;
  }

  return environment.logger.child(bindings);
}

/* -------------------------------------------------------------------------------------------------
 *  utility functions
 * -------------------------------------------------------------------------------------------------
 */

function invertTransaction(environment: ClientEnvironment, transaction: Transaction) {
  const { db } = environment;
  const { operations, authorId } = transaction;

  // Get cached records for this write.
  const pointers = uniqWith(operations.flatMap(getOperationPointers), isEqual);
  const [pointerWithRecords] = db.getRecords(pointers, { includeSoftDeletes: true });
  const recordMap = createRecordMapFromPointersWithRecords(pointerWithRecords);

  const undoOperations: Operation[] = [];
  for (const operation of operations) {
    const invert = invertOperation({ recordMap, operation, authorId });
    if (invert) undoOperations.push(invert);
  }

  if (undoOperations.length) {
    const undoTransaction = op.transaction({
      label: transaction.label?.startsWith("Undo") ? `Redo ${transaction.label}` : `Undo ${transaction.label}`,
      authorId,
      operations: undoOperations,
    });

    return undoTransaction;
  }
}

/* -----------------------------------------------------------------------------------------------*/

/**
 * This function syncronously writes the transaction to the in-memory database and then asynchronously
 * writes it to the persisted database. The returned promise will resolve when the transaction has finished
 * being applied to both databases.
 */
// Important! Should be a sync function. See comments below.
function optimisticWrite(
  environment: Pick<ClientEnvironment, "db" | "writeRecordMap" | "logger">,
  transaction: Transaction,
): Promise<void> {
  const { db, writeRecordMap } = environment;

  // Get cached records for this write.
  const pointers = uniqWith(transaction.operations.flatMap(getOperationPointers), isEqual);
  const [pointerWithRecords] = db.getRecords(pointers, { includeSoftDeletes: true });
  const recordMap = createRecordMapFromPointersWithRecords(pointerWithRecords);

  const currentTimestamp = new Date().toISOString();

  // Optimistic update.
  applyTransaction({
    recordMap,
    transaction,
    currentTimestamp,
    isServer: false,
  });

  environment.logger.debug({ recordMap, transaction }, `[${transaction.label}] optimisticWrite`);

  // Note that writeRecordMap itself is not an async function but it returns a promise.
  // It synchronously updates the in memory cache and async updates the persisted database.
  // Similarly, we want this optimistic write to be synchronous on the in-memory database
  // so we don't want to make this an async function.
  return writeRecordMap(recordMap).catch((error) => {
    environment.logger.error(
      { transaction, error },
      // The in-memory database will error synchronously so if we've caught a rejected promise here
      // that means it came from the persisted database.
      `[${transaction.label}] error applying optimistic write to persisted db`,
    );
  });
}

/* -----------------------------------------------------------------------------------------------*/

function enqueueTransaction(
  environment: Pick<ClientEnvironment, "transactionQueue" | "logger">,
  transaction: Transaction,
) {
  const { transactionQueue, logger } = environment;

  return transactionQueue.enqueue(transaction).then(
    () => {
      logger.notice(transaction, `[transactionQueue] [${transaction.label}] successfully sent to server`);
    },
    (error) => {
      logger.error({ ...transaction, error }, `[transactionQueue] [enqueue] [${transaction.label}] error`);
    },
  );
}

/* -----------------------------------------------------------------------------------------------*/
