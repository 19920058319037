import type {
  AuthToken,
  DeletedRow,
  Draft,
  Message,
  MessageReactions,
  Notification,
  Organization,
  OrganizationControlledDomain,
  OrganizationProfile,
  OrganizationUserInvitation,
  OrganizationUserMember,
  Prisma,
  Tag,
  TagMetadata,
  TagFolderMember,
  TagGroupMember,
  TagSubscription,
  TagUserMember,
  Thread,
  ThreadGroupPermission,
  ThreadReadReceipt,
  ThreadSeenReceipt,
  ThreadSubscription,
  ThreadTimeline,
  ThreadUserParticipant,
  ThreadUserPermission,
  User,
  UserContactInfo,
  UserLesson,
  UserOAuth,
  UserProfile,
  UserSettings,
  UserPushNotificationSubscriptions,
  ChangeNotification,
  ThreadTag,
} from "@prisma/client/edge";

// export type * from "@prisma/client/edge";

import type { JsonObject, JsonValue, Merge, Simplify } from "type-fest";
import type { ImplementsInterface } from "libs/type-helpers";

import type { ParsedToken } from "libs/searchQueryParser";

/* -------------------------------------------------------------------------------------------------
 * Table of Contents i.e. TableToRecord
 * -----------------------------------------------------------------------------------------------*/

// TableToRecord is exported because, even though it is not directly used outside this file, typescript
// requires it to be exported for the `RecordTable` type to work. Otherwise typescript throws an error
// and requests that we export this type.
export type TableToRecord = {
  auth_token: AuthTokenRecord;
  // deleted_row: DeletedRowRecord;
  draft: DraftRecord;
  inbox_entry: InboxEntryRecord;
  message: MessageRecord;
  message_reactions: MessageReactionsRecord;
  notification: NotificationRecord;
  organization_controlled_domain: OrganizationControlledDomainRecord;
  organization_profile: OrganizationProfileRecord;
  organization_user_invitation: OrganizationUserInvitationRecord;
  organization_user_member: OrganizationUserMemberRecord;
  organization: OrganizationRecord;
  tag: TagRecord;
  tag_folder_member: TagFolderMemberRecord;
  tag_group_member: TagGroupMemberRecord;
  tag_metadata: TagMetadataRecord;
  tag_subscription: TagSubscriptionRecord;
  tag_user_member: TagUserMemberRecord;
  thread: ThreadRecord;
  thread_group_permission: ThreadGroupPermissionRecord;
  thread_read_receipt: ThreadReadReceiptRecord;
  thread_seen_receipt: ThreadSeenReceiptRecord;
  thread_subscription: ThreadSubscriptionRecord;
  thread_tag: ThreadTagRecord;
  thread_timeline: ThreadTimelineRecord;
  thread_user_participant: ThreadUserParticipantRecord;
  thread_user_permission: ThreadUserPermissionRecord;
  user: UserRecord;
  user_contact_info: UserContactInfoRecord;
  user_lesson: UserLessonRecord;
  user_oauth: UserOAuthRecord;
  user_profile: UserProfileRecord;
  user_push_notification_subscriptions: UserPushNotificationSubscriptionRecord;
  user_settings: UserSettingsRecord;
};

/* -------------------------------------------------------------------------------------------------
 * Record types
 * -------------------------------------------------------------------------------------------------
 * In Comms we use "record" to refer to a database row and we use "doc" (i.e. document) to refer
 * to a json object stored within a cell in a row (i.e. a jsonb or json column).
 */

type AuthTokenRecord = Simplify<WithDateAsString<AuthToken>>;

/**
 * Note that the DeletedRowRecord interface is not the same as what
 * we persist to the deleted_row table in the database. This interface
 * makes all field nullable except for id, row_table, row_id, and version.
 * When the server returns a deleted_row record to the client, that record
 * will only have these fields populated.
 */
type DeletedRowRecord = Simplify<
  ImplementsInterface<
    DeletedRow,
    {
      id: string;
      row_table: RecordTable;
      row_id: string;
      version: number;
      data: Prisma.JsonObject | null;
      owner_organization_id: string | null;
      created_at: string | null;
      updated_at: string | null;
      server_updated_at: string | null;
      deleted_at: string | null;
      deleted_by_user_id: string | null;
    }
  >
>;

type DraftRecord = Merge<
  WithDateAsString<Draft>,
  {
    type: DraftType;
    new_thread_visibility: ThreadVisibility | null;
    to: DraftRecipientDoc[];
    attachments: DraftAttachmentDoc[];
  }
>;

/**
 * _**Virtual table**_
 *
 * "inbox_entry" is a virtual record type that is returned by some
 * queries but which has no associated table in the database
 */
type InboxEntryRecord = {
  id: string;
  type: "draft" | "notification";
  user_id: string;
  inbox_section_id: string;
  thread_id: string;
  draft_id: string | null;
  draft_is_reply: boolean | null;
  notification_id: string | null;
  inbox_subsection_id: string | null;
  inbox_subsection_order: string | null;
  order: string;
  // The following fields are only included for consistency since other
  // "real" records all include these fields.
  owner_organization_id: string;
  version: number;
  created_at: string;
  updated_at: string;
  server_updated_at: string | null;
  deleted_at: string | null;
  deleted_by_user_id: string | null;
};

type MessageRecord = Merge<
  WithDateAsString<Message>,
  {
    type: MessageType;
    to: MessageRecipientDoc[];
    attachments: MessageAttachmentDoc[];
    data: null | {
      [key: string]: unknown;
      /**
       * Setting this `true` will cause the message notifications to be re-deliverd
       *  to all recipients. The property will then be automatically set to `false`
       * after the message is resent.
       */
      resend_notifications?: boolean;
    };
  }
>;

type MessageReactionsRecord = Merge<
  Simplify<WithDateAsString<MessageReactions>>,
  {
    /**
     * Map of userIds to an array of that user's reactions. Each element of
     * the array is a string equal to the reaction emoji.
     */
    reactions: {
      [userId: string]: string[];
    };
  }
>;

type NotificationRecord = Merge<
  WithDateAsString<Notification>,
  {
    /** It's possible for this array to contain IDs for deleted tags */
    tag_ids: string[];
    done_last_modified_by: MessageModifiedBy;
  }
>;

type OrganizationRecord = Simplify<WithDateAsString<Organization>>;
type OrganizationControlledDomainRecord = Simplify<WithDateAsString<OrganizationControlledDomain>>;
type OrganizationProfileRecord = Simplify<WithDateAsString<OrganizationProfile>>;
type OrganizationUserInvitationRecord = Simplify<WithDateAsString<OrganizationUserInvitation>>;
/**
 * Organizations have a canonical "group" tag with the same ID
 * as the organization. Members of this group _are_ members of
 * this organization. We also store organization_user_member
 * records primarily as a performance optimization so that we can quickly
 * lookup which organizations a user is a member of.
 */
type OrganizationUserMemberRecord = Simplify<WithDateAsString<OrganizationUserMember>>;

type TagRecord = Merge<
  WithDateAsString<Tag>,
  {
    data: null | {
      [key: string]: unknown;
      /**
       * If this tag has organization groups which are members,
       * we store those IDs here. This allows us to determine if this tag
       * has "shared" or "private" visibility. This is most useful for groups.
       */
      organization_group_member_ids?: string[];
    };
  }
>;

type TagMetadataRecord = Simplify<WithDateAsString<TagMetadata>>;
type TagFolderMemberRecord = Simplify<WithDateAsString<TagFolderMember>>;
type TagGroupMemberRecord = Simplify<WithDateAsString<TagGroupMember>>;
type TagSubscriptionRecord = Merge<
  WithDateAsString<TagSubscription>,
  {
    preference: TagSubscriptionPreference;
  }
>;
type TagUserMemberRecord = Simplify<WithDateAsString<TagUserMember>>;

type ThreadRecord = Merge<WithDateAsString<Thread>, { type: ThreadType; visibility: ThreadVisibility }>;
type ThreadGroupPermissionRecord = Simplify<WithDateAsString<ThreadGroupPermission>>;
type ThreadReadReceiptRecord = Simplify<WithDateAsString<ThreadReadReceipt>>;
type ThreadSeenReceiptRecord = Simplify<WithDateAsString<ThreadSeenReceipt>>;
type ThreadSubscriptionRecord = Merge<
  WithDateAsString<ThreadSubscription>,
  {
    preference: ThreadSubscriptionPreference;
  }
>;
type ThreadTagRecord = Merge<
  WithDateAsString<ThreadTag>,
  {
    data: JsonObject | null;
  }
>;
type ThreadTimelineRecord = Merge<
  WithDateAsString<ThreadTimeline>,
  {
    type: ThreadTimelineType;
  }
>;
type ThreadUserParticipantRecord = Simplify<WithDateAsString<ThreadUserParticipant>>;
type ThreadUserPermissionRecord = Simplify<WithDateAsString<ThreadUserPermission>>;

type UserRecord = Simplify<WithDateAsString<User>>;
type UserContactInfoRecord = Simplify<WithDateAsString<UserContactInfo>>;
type UserLessonRecord = Simplify<WithDateAsString<UserLesson>>;
type UserOAuthRecord = Simplify<WithDateAsString<UserOAuth>>;
type UserPushNotificationSubscriptionRecord = Merge<
  WithDateAsString<UserPushNotificationSubscriptions>,
  {
    subscription: FcmTokenOrPushSubscription;
  }
>;

export type WebPushSubscription = {
  endpoint: string;
  expirationTime?: EpochTimeStamp | null;
  keys: {
    p256dh: string;
    auth: string;
  };
};

export type FcmToken = {
  type: "fcm";
  token: string;
};

export type FcmTokenOrPushSubscription = WebPushSubscription | FcmToken;

export function isFcmToken(subscription: FcmTokenOrPushSubscription): subscription is FcmToken {
  return (subscription as FcmToken).type === "fcm";
}

type EpochTimeStamp = number;

type UserProfileRecord = Merge<
  WithDateAsString<UserProfile>,
  {
    /**
     * The name field is automatically generated in the database by
     * concatonating the first, middle, and last name fields. It is
     * read-only.
     */
    name: string;
  }
>;

type UserSettingsRecord = Merge<
  WithDateAsString<UserSettings>,
  {
    settings: UserSettingsDoc;
    mention_frequency: MentionFrequencyMapDoc;
  }
>;

/* -----------------------------------------------------------------------------------------------*/

export type ChangeNotificationRecord = Merge<
  WithDateAsString<ChangeNotification>,
  {
    data: JsonValue;
  }
>;

export type ClientSingletonRecordName = keyof ClientSingletonRecordMap;

export type ClientSingletonRecord<Name extends ClientSingletonRecordName = ClientSingletonRecordName> =
  ClientSingletonRecordMap[Name];

type ClientSingletonRecordMap = {
  sync_data: Merge<
    ClientSingletonRecordBase,
    {
      name: "sync_data";
      data: {
        last_change_notification_id?: number;
        full_sync_completed_at?: string | null;
        full_sync_stop_at_inbox_notification_id?: string | null;
        full_sync_last_synced_inbox_notification_id?: string | null;
      };
    }
  >;
};

type ClientSingletonRecordBase = {
  name: string;
  data: JsonObject;
  version: number;
  updated_at: string;
};

/* -----------------------------------------------------------------------------------------------*/

export type DraftType = "COMMS" | "EMAIL";
export type MentionPriority = 100 | 200 | 300;
export type MessageModifiedBy = "user" | "reminder" | "delivery";
export type MessageType = ThreadType;
export type MessageReaction = { id: string; count: number };
export type TagSubscriptionPreference = "all" | "all-new" | "involved";

export const TagSubscriptionPreferenceEnum = {
  ALL: "all",
  ALL_NEW: "all-new",
  /**
   * "involved" means that the user wants a notification if they are mentioned in
   * a message OR if they are participating in a thread and a new message is sent
   * to that thread. This is the default behavior for Comms. This subscription level
   * is equivalent to "unsubscribed".
   */
  INVOLVED: "involved",
} as const;

export type ThreadSubscriptionPreference = "all" | "involved";
export type ThreadType = "COMMS" | "EMAIL" | "EMAIL_BCC";
export type ThreadVisibility = "SHARED" | "PRIVATE";
export type ThreadTimelineType = "MESSAGE" | "BRANCHED_THREAD" | "BRANCHED_DRAFT";

export const ThreadTimelineTypeEnum = {
  BRANCHED_DRAFT: "BRANCHED_DRAFT",
  BRANCHED_THREAD: "BRANCHED_THREAD",
  MESSAGE: "MESSAGE",
} as const;

export const SpecialTagTypeEnum = {
  GROUP: "_GROUP",
  INBOX_SECTION: "_INBOX_SECTION",
  INBOX_SUBSECTION: "_INBOX_SUBSECTION",
  SINGLETON: "_SINGLETON",
} as const;

/* -----------------------------------------------------------------------------------------------*/

// We use Prisma to generate our base record types. Prisma assumes that we're
// using Prisma client for querying so Date fields are returned from postgres
// as Date objects. We're not using Prisma client for querying so Date fields
// are returned from postgres as strings.
type WithDateAsString<T> = {
  [P in keyof T]: Date extends T[P] ? (null extends T[P] ? string | null : string) : T[P];
};

/* -------------------------------------------------------------------------------------------------
 * Record subtypes
 * -------------------------------------------------------------------------------------------------
 * The following are some notable record subtypes.
 */

/* -----------------------------------------------------------------------------------------------*/

export type SpecialTagRecord = GroupTagRecord | InboxSectionTagRecord | InboxSubsectionTagRecord | SingletonTagRecord;

export type GroupTagRecord = Simplify<
  Merge<
    TagRecord,
    {
      type: (typeof SpecialTagTypeEnum)["GROUP"];
      data: null | Merge<
        TagRecord["data"],
        {
          /**
           * `true` if this tag is a group and represents an organization.
           */
          is_organization_group?: boolean;
          /**
           * An array of organization IDs. When users are added to one of these
           * organizations they should be automatically subscribed to this group.
           * The option should only be used for shared (i.e. not private) groups.
           */
          subscribe_new_users_of_orgs?: string[];
        }
      >;
    }
  >
>;

export type InboxSectionTagRecord = Simplify<
  Merge<
    TagRecord,
    {
      type: (typeof SpecialTagTypeEnum)["INBOX_SECTION"];
      /** Equal to the name of the associated inbox section */
      name: string;
      description: null;
      data: Merge<
        TagRecord["data"],
        {
          /**
           * Inbox sections are always just used by a specific user. We cache that user_id
           * here to speed up some queries.
           */
          user_id: string;
          order: number;
          is_reindexing?: boolean;
        }
      >;
    }
  >
>;

export type InboxSubsectionTagRecord = Simplify<
  Merge<
    TagRecord,
    {
      type: (typeof SpecialTagTypeEnum)["INBOX_SUBSECTION"];
      /** Equal to the name of the associated inbox subsection */
      name: string;
      description: string | null;
      data: Merge<
        TagRecord["data"],
        {
          /**
           * Inbox subsections are always just used by a specific user. We cache that user_id
           * here to speed up some queries.
           */
          user_id: string;
          inbox_section_id: string;
          order: number;
          query: string;
          parsed_query: ParsedToken[];
        }
      >;
    }
  >
>;

export type SingletonTagRecord = ResolvedTagRecord;

export type ResolvedTagRecord = Simplify<
  Merge<
    TagRecord,
    {
      type: (typeof SpecialTagTypeEnum)["SINGLETON"];
      name: "Resolved";
    }
  >
>;

export type ThreadResolvedThreadTagRecord = Simplify<
  Merge<
    ThreadTagRecord,
    {
      data: {
        /** The ID of the message that resolved the thread. */
        message_id: string;
      };
    }
  >
>;

/* -----------------------------------------------------------------------------------------------*/

export type ThreadTimelineSubtype =
  | ThreadTimelineMessageRecord
  | ThreadTimelineBranchedThreadRecord
  | ThreadTimelineBranchedDraftRecord;

export interface ThreadTimelineBranchedDraftRecord extends ThreadTimelineRecord {
  /** Branched thread's ID */
  id: string;
  type: "BRANCHED_DRAFT";
  /**
   * The order is determined by
   * 1. The message branched from "sent_at"
   * 2. The message branched from "scheduled_to_be_sent_at"
   * 3. The draft "created_at"
   */
  order: string;
}

export interface ThreadTimelineBranchedThreadRecord extends ThreadTimelineRecord {
  /** Branched thread's ID */
  id: string;
  type: "BRANCHED_THREAD";
  /**
   * The order is determined by
   * 1. The message branched from "sent_at"
   * 2. The message branched from "scheduled_to_be_sent_at"
   * 3. The first message in the branched thread "sent_at"
   * 4. The first message in the branched thread "scheduled_to_be_sent_at"
   */
  order: string;
}

export interface ThreadTimelineMessageRecord extends ThreadTimelineRecord {
  /** Message's ID */
  id: string;
  type: "MESSAGE";
  /**
   * The order is determined by
   * 1. The related message "sent_at"
   * 2. The related message "scheduled_to_be_sent_at"
   */
  order: string;
}

/* -------------------------------------------------------------------------------------------------
 * Embedded document types
 * -------------------------------------------------------------------------------------------------
 * In Comms we use "record" to refer to a database row and we use "doc" (i.e. document) to refer
 * to a json object stored within a cell in a row (i.e. a jsonb or json column).
 */

export type DraftGroupRecipientDoc = {
  /** id is derived from [type, group_id] */
  id: string;
  type: "GROUP";
  group_id: string;
  priority: number;
  /**
   * true if this recipient was automatically added to the message
   * because the message mentioned this recipient.
   * false if this recipient was explicitly added by the sender.
   * this property is needed to properly handle editing of messages
   */
  is_implicit: boolean;
  is_mentioned: boolean;
};

export type DraftUserRecipientDoc = {
  /** id is derived from [type, user_id] */
  id: string;
  type: "USER";
  user_id: string;
  priority: number;
  /**
   * true if this recipient was automatically added to the message
   * because the message mentioned this recipient.
   * false if this recipient was explicitly added by the sender.
   * this property is needed to properly handle editing of messages
   */
  is_implicit: boolean;
  is_mentioned: boolean;
};

export type DraftRecipientDoc = DraftGroupRecipientDoc | DraftUserRecipientDoc;

export type DraftAttachmentDoc = {
  /**
   * Note that a single attachment might be shared between multiple
   * messages or between messages and drafts. In this case, each of these
   * records will have a nested attachment doc with the same ID.
   */
  id: string;
  fileName: string | null;
  /**
   * Whether the attachment should be rendered as an attachment or if
   * it's inline (i.e. embedded) within the message. Value is either
   * "inline" or "attachment". If `null`, then the client gets
   * to choose.
   */
  contentDisposition: AttachmentContentDisposition | null;
  contentType: string | null;
  fileSize: number | null;
  /**
   * If there was an upload error, this is a string with the error message.
   */
  errorMsg?: string;
};

export type AttachmentContentDisposition = "inline" | "attachment";

export type MessageAttachmentDoc = DraftAttachmentDoc;

export type MessageRecipientDoc = MessageUserRecipientDoc | MessageGroupRecipientDoc;

export type MessageUserRecipientDoc = DraftUserRecipientDoc;
export type MessageGroupRecipientDoc = DraftGroupRecipientDoc;

/**
 * There is one record for each subject, where a subject is any record that can be
 * mentioned. The keys have the format `table:subject_id`.
 */
export type MentionFrequencyMapDoc = {
  [key: string]: SubjectMentionFrequencyDoc;
};

/** For use with a `MentionFrequencyMapDoc` */
export function getMentionFrequencyKey(pointer: RecordPointer) {
  return `${pointer.table}:${pointer.id}`;
}

/**
 * This is a rolling count of the number of times a subject has been
 * mentioned in the past month by the current user.
 * As usage patterns change, our frequency numbers will
 * reflect that. Week1 is the count for the first week of the month,
 * week2 the count for the second, etc. As one month moves into the
 * next, we'll overrite the week1 value for the previous month with
 * the value for the next month.
 *
 * These values are updated by the client when a user sends a message.
 * If a user doesn't log in for a while, these values will not be updated
 * and hence reflect the last known values when the user next logs in.
 */
export interface SubjectMentionFrequencyDoc {
  week1Count: number;
  week1LastUpdatedAt: string;
  week2Count: number;
  week2LastUpdatedAt: string;
  week3Count: number;
  week3LastUpdatedAt: string;
  week4Count: number;
  week4LastUpdatedAt: string;
  week5Count: number;
  week5LastUpdatedAt: string;
}

export type UserSettingsDoc = {
  /**
   * - Consolidated inbox shows all of your messages at once,
   *   grouped by priority.
   * - Blocking inbox also groups messages by priority
   *   but only allows you to see the highest priority
   *   messages at any given time.
   */
  inbox_layout?: "consolidated-inbox" | "blocking-inbox";

  /**
   * Navigate "Back" when marking a thread as "Done". By default,
   * Comms will instead try to navigate the user to the next
   * thread, where "next thread" is context specific.
   */
  enable_nav_back_on_thread_done?: boolean;

  /**
   * Manually toggled by the user to enable focus mode until
   * they decide to un-toggle it.
   */
  enable_focus_mode?: boolean;
  /**
   * An array of priorities that should be delivered even
   * while focus mode is turned on.
   */
  focus_mode_exceptions?: number[];
  /**
   * When true, then inbox items are only delivered at the days/times
   * the user specifies.
   */
  enable_scheduled_delivery?: boolean;
  /**
   * On what days should scheduled delivery happen?
   */
  scheduled_days?: string[];
  /**
   * At what times should scheduled delivery happen?
   */
  scheduled_times?: string[];
  /**
   * The time the user most recently pressed the "deliver now"
   * button and bypassed their standard scheduled delivery.
   */
  most_recent_deliver_now?: number | null;
  /**
   * When this value is provided and greater than 0, the user
   * will be required to wait this many seconds in order to
   * disable scheduled delivery. A value of 0 will disable the
   * friction timer, allowing the user to toggle scheduled
   * delivery on and off instantly.
   */
  seconds_to_wait_to_disable_scheduled_delivery?: number;
  /**
   * - `true` if the user has linked their Gmail email account.
   * - `"reauthorize"` indicates that the user previously linked
   *   their Gmail email account, but for whatever reason they
   *   need to do so again.
   */
  linked_gmail_email_account?: boolean | "reauthorize";

  /**
   * After sending a message, the user will be given the option
   * to undo the sending for this many seconds. Note that,
   * unless this value is 0, Comms will schedule the message to
   * be sent in current time + secondsForUndoingSentMessage + 10
   * seconds. The 10 additional seconds is to accomodate undoing
   * the sending of the message on a slow network connection.
   *
   * @default 10
   */
  seconds_for_undoing_sent_message?: number;

  /**
   * Whether or not this user should receive a text message for
   * `@@@` notifications. Note that the user will also need a phone
   * number associated with their account for this setting to be
   * honored.
   */
  interrupt_message_text?: boolean;
  /**
   * Whether or not to show archived groups on the kbar
   */
  show_archived_groups?: boolean;

  /**
   * Whether to show reminders in the inbox when a thread is updated.
   */
  dont_show_reminders_in_inbox_unless_mentioned?: boolean;

  /**
   * Enables an option to block time on Google Calendar from a thread
   */
  enable_google_calendar?: boolean;
};

/* -------------------------------------------------------------------------------------------------
 * Utilities
 * -----------------------------------------------------------------------------------------------*/

export type RecordMap = {
  [T in RecordTable]?: {
    [id: string]: TableToRecord[T];
  };
};

export type RecordTable = keyof TableToRecord;

export type RecordValue<T extends RecordTable = RecordTable> = TableToRecord[T];

export type PointerWithRecord<T extends RecordTable = RecordTable> = {
  [K in T]: { table: K; id: string; record: RecordValue<K> };
}[T];

export type RecordPointer<T extends RecordTable = RecordTable> = {
  [K in T]: { table: K; id: string };
}[T];

/**
 * -------------------------------------------------------------------------------------------------
 * tableProps
 * -------------------------------------------------------------------------------------------------
 *
 * Maintains a list of properties for each table.
 */

export const tablePropsMap = {
  auth_token: {
    id: true,
    user_id: true,
    expires_at: true,
    is_api_key: true,
    owner_organization_id: true,
    version: true,
    created_at: true,
    updated_at: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  // deleted_row: {
  //   id: true,
  //   row_table: true,
  //   row_id: true,
  //   data: true,
  //   owner_organization_id: true,
  //   version: true,
  //   created_at: true,
  //   updated_at: true,
  //   server_updated_at: true,
  //   deleted_at: true,
  //   deleted_by_user_id: true,
  // },
  draft: {
    id: true,
    user_id: true,
    body_html: true,
    branched_from_message_id: true,
    branched_from_thread_id: true,
    is_reply: true,
    is_edit: true,
    new_thread_subject: true,
    new_thread_visibility: true,
    thread_id: true,
    type: true,
    to: true,
    attachments: true,
    owner_organization_id: true,
    version: true,
    created_at: true,
    updated_at: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  inbox_entry: {
    id: true,
    inbox_section_id: true,
    draft_id: true,
    inbox_subsection_id: true,
    notification_id: true,
    type: true,
    order: true,
    user_id: true,
    inbox_subsection_order: true,
    draft_is_reply: true,
    thread_id: true,
    created_at: true,
    owner_organization_id: true,
    updated_at: true,
    version: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  message: {
    attachments: true,
    body_html: true,
    body_text: true,
    created_at: true,
    data: true,
    delivered_at: true,
    email_message_id: true,
    to: true,
    id: true,
    is_reply: true,
    is_delivered: true,
    last_edited_at: true,
    owner_organization_id: true,
    scheduled_to_be_sent_at: true,
    sender_user_id: true,
    sent_at: true,
    timeline_order: true,
    subject: true,
    thread_id: true,
    type: true,
    updated_at: true,
    version: true,
    was_edited: true,
    email_sender: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  message_reactions: {
    created_at: true,
    thread_id: true,
    id: true,
    owner_organization_id: true,
    reactions: true,
    updated_at: true,
    version: true,
    message_sent_at: true,
    message_timeline_order: true,
    message_sender_user_id: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  notification: {
    created_at: true,
    done_at: true,
    done_last_modified_by: true,
    has_reminder: true,
    id: true,
    is_done: true,
    message_id: true,
    thread_type: true,
    oldest_message_not_marked_done_message_id: true,
    oldest_message_not_marked_done_sent_at: true,
    owner_organization_id: true,
    sent_at: true,
    priority: true,
    remind_at: true,
    is_starred: true,
    starred_at: true,
    tag_ids: true,
    thread_id: true,
    is_delivered: true,
    delivered_at: true,
    updated_at: true,
    user_id: true,
    version: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  organization: {
    created_at: true,
    id: true,
    owner_organization_id: true,
    updated_at: true,
    version: true,
    premium: true,
    stripe_customer_id: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  organization_controlled_domain: {
    created_at: true,
    domain: true,
    id: true,
    organization_id: true,
    owner_organization_id: true,
    updated_at: true,
    version: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  organization_profile: {
    created_at: true,
    id: true,
    name: true,
    name_short: true,
    owner_organization_id: true,
    photo_url: true,
    updated_at: true,
    version: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  organization_user_invitation: {
    created_at: true,
    creator_user_id: true,
    email_address: true,
    expires_at: true,
    id: true,
    organization_id: true,
    owner_organization_id: true,
    updated_at: true,
    version: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  organization_user_member: {
    created_at: true,
    creator_user_id: true,
    id: true,
    is_admin: true,
    organization_id: true,
    owner_organization_id: true,
    updated_at: true,
    user_id: true,
    version: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  tag: {
    created_at: true,
    data: true,
    description: true,
    icon: true,
    id: true,
    name: true,
    owner_organization_id: true,
    type: true,
    updated_at: true,
    version: true,
    archived_at: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  tag_metadata: {
    created_at: true,
    id: true,
    data: true,
    owner_organization_id: true,
    updated_at: true,
    version: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  tag_folder_member: {
    created_at: true,
    creator_user_id: true,
    folder_id: true,
    tag_id: true,
    id: true,
    owner_organization_id: true,
    updated_at: true,
    version: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  tag_group_member: {
    created_at: true,
    creator_user_id: true,
    group_id: true,
    id: true,
    is_organization_group: true,
    owner_organization_id: true,
    tag_id: true,
    updated_at: true,
    version: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  tag_subscription: {
    created_at: true,
    creator_user_id: true,
    id: true,
    owner_organization_id: true,
    preference: true,
    tag_id: true,
    updated_at: true,
    user_id: true,
    version: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  tag_user_member: {
    created_at: true,
    creator_user_id: true,
    id: true,
    owner_organization_id: true,
    tag_id: true,
    updated_at: true,
    user_id: true,
    version: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  thread: {
    branched_from_message_id: true,
    branched_from_thread_id: true,
    is_branch: true,
    created_at: true,
    first_message_sender_user_id: true,
    first_message_id: true,
    first_message_timeline_order: true,
    first_message_sent_at: true,
    id: true,
    last_message_id: true,
    last_message_sent_at: true,
    last_message_timeline_order: true,
    owner_organization_id: true,
    subject: true,
    type: true,
    updated_at: true,
    version: true,
    visibility: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  thread_group_permission: {
    created_at: true,
    group_id: true,
    id: true,
    owner_organization_id: true,
    start_at: true,
    thread_id: true,
    updated_at: true,
    version: true,
    creator_user_id: true,
    thread_sent_at: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  thread_timeline: {
    created_at: true,
    data: true,
    entry_id: true,
    id: true,
    order: true,
    creator_user_id: true,
    owner_organization_id: true,
    thread_id: true,
    type: true,
    updated_at: true,
    version: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  thread_read_receipt: {
    created_at: true,
    id: true,
    owner_organization_id: true,
    read_to_timeline_id: true,
    read_to_timeline_order: true,
    thread_id: true,
    updated_at: true,
    user_id: true,
    version: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  thread_seen_receipt: {
    created_at: true,
    id: true,
    owner_organization_id: true,
    seen_to_timeline_id: true,
    seen_to_timeline_order: true,
    thread_id: true,
    updated_at: true,
    user_id: true,
    version: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  thread_subscription: {
    created_at: true,
    id: true,
    owner_organization_id: true,
    preference: true,
    thread_id: true,
    updated_at: true,
    user_id: true,
    version: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  thread_tag: {
    id: true,
    thread_id: true,
    tag_id: true,
    creator_user_id: true,
    data: true,
    owner_organization_id: true,
    version: true,
    created_at: true,
    updated_at: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  thread_user_participant: {
    created_at: true,
    id: true,
    owner_organization_id: true,
    thread_id: true,
    updated_at: true,
    user_id: true,
    version: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  thread_user_permission: {
    created_at: true,
    id: true,
    owner_organization_id: true,
    start_at: true,
    thread_id: true,
    updated_at: true,
    user_id: true,
    version: true,
    creator_user_id: true,
    thread_sent_at: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  user: {
    created_at: true,
    email: true,
    email_verified: true,
    email_verified_at: true,
    firebase_auth_id: true,
    disabled_at: true,
    is_disabled: true,
    id: true,
    owner_organization_id: true,
    updated_at: true,
    version: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  user_contact_info: {
    created_at: true,
    email_address: true,
    id: true,
    owner_organization_id: true,
    phone_number: true,
    updated_at: true,
    version: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  user_lesson: {
    created_at: true,
    id: true,
    is_completed: true,
    lesson_id: true,
    lesson_version: true,
    owner_organization_id: true,
    updated_at: true,
    user_id: true,
    version: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  user_oauth: {
    created_at: true,
    email: true,
    federated_id: true,
    firebase_auth_id: true,
    firebase_id_token: true,
    firebase_id_token_expires_at: true,
    firebase_refresh_token: true,
    id: true,
    is_linked_to_user: true,
    oauth_access_token: true,
    oauth_id_token: true,
    owner_organization_id: true,
    provider: true,
    updated_at: true,
    user_id: true,
    version: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  user_profile: {
    created_at: true,
    first_name: true,
    id: true,
    last_name: true,
    owner_organization_id: true,
    photo_url: true,
    updated_at: true,
    version: true,
    middle_name: true,
    name: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  user_settings: {
    created_at: true,
    id: true,
    owner_organization_id: true,
    settings: true,
    mention_frequency: true,
    updated_at: true,
    version: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
  user_push_notification_subscriptions: {
    id: true,
    user_id: true,
    owner_organization_id: true,
    subscription: true,
    version: true,
    created_at: true,
    updated_at: true,
    server_updated_at: true,
    deleted_at: true,
    deleted_by_user_id: true,
  },
} satisfies {
  [T in RecordTable]: { [P in keyof TableToRecord[T]]: true };
};

export const tableProps = Object.fromEntries(
  Object.entries(tablePropsMap).map(([table, propsMap]) => [table, Object.keys(propsMap)]),
) as unknown as {
  readonly [T in RecordTable]: ReadonlyArray<keyof TableToRecord[T] & string>;
};

/* -------------------------------------------------------------------------------------------------
 * tablePKeys
 * -----------------------------------------------------------------------------------------------*/

/**
 * Comms requires that every record have an "id" value, but
 * some records derive that id value from other columns. This object
 * maps table names to the columns used to derive the id value. If
 * an id value isn't derived from other columns, then it is derived
 * from itself.
 */
export const tablePKeys = {
  auth_token: ["id"],
  // deleted_row: ["row_table", "row_id"],
  draft: ["id"],
  inbox_entry: ["id"],
  message_reactions: ["id"],
  message: ["id"],
  notification: ["user_id", "thread_id"],
  organization_controlled_domain: ["domain", "organization_id"],
  organization_profile: ["id"],
  organization_user_invitation: ["organization_id", "email_address"],
  organization_user_member: ["organization_id", "user_id"],
  organization: ["id"],
  tag_folder_member: ["tag_id", "folder_id"],
  tag_group_member: ["tag_id", "group_id"],
  tag_subscription: ["user_id", "tag_id"],
  tag_user_member: ["tag_id", "user_id"],
  tag_metadata: ["id"],
  tag: ["id"],
  thread_group_permission: ["thread_id", "group_id"],
  thread_read_receipt: ["thread_id", "user_id"],
  thread_seen_receipt: ["thread_id", "user_id"],
  thread_subscription: ["user_id", "thread_id"],
  thread_tag: ["thread_id", "tag_id"],
  thread_timeline: ["entry_id", "thread_id"],
  thread_user_participant: ["thread_id", "user_id"],
  thread_user_permission: ["thread_id", "user_id"],
  thread: ["id"],
  user_contact_info: ["id"],
  user_lesson: ["user_id", "lesson_id"],
  user_oauth: ["id"],
  user_profile: ["id"],
  user_settings: ["id"],
  user: ["id"],
  user_push_notification_subscriptions: ["id"],
} as const satisfies {
  readonly [Table in RecordTable]: ReadonlyArray<keyof RecordValue<Table>>;
};

export type JoinTable = {
  [Table in RecordTable]: (typeof tablePKeys)[Table] extends readonly ["id"] ? never : Table;
}[RecordTable];

export type NonJoinTable = {
  [Table in RecordTable]: (typeof tablePKeys)[Table] extends readonly ["id"] ? Table : never;
}[RecordTable];

export type TablePKey<T extends RecordTable> =
  // We need to help typescript realize that PKeys are always keyof the
  // associated record
  TablePKeyInner<T> extends keyof RecordValue<T> ? TablePKeyInner<T> : never;

type TablePKeyInner<T extends RecordTable> = (typeof tablePKeys)[T][number];

export type JoinRecordPKey<T extends JoinTable = JoinTable> = Pick<RecordValue<T>, TablePKey<T>>;

export const joinTables = Object.keys(tablePKeys).filter(
  (table) => tablePKeys[table as RecordTable].length > 1,
) as JoinTable[];

export const tablesWithIdEqualToOtherTableId = {
  message_reactions: "message",
  organization_profile: "organization",
  tag_metadata: "tag",
  user_contact_info: "user",
  user_profile: "user",
  user_settings: "user",
} as const satisfies Partial<{
  readonly [Table in RecordTable]: RecordTable;
}>;

export type TableHasIdEqualToOtherTableId = keyof typeof tablesWithIdEqualToOtherTableId;

/* -----------------------------------------------------------------------------------------------*/

export const TABLE_NAMES = Object.keys(tablePKeys) as RecordTable[];

/* -------------------------------------------------------------------------------------------------
 * tableFilterKeys
 * -----------------------------------------------------------------------------------------------*/

/**
 * This object maps table names to the columns that can be used to filter
 * records.
 */
export const tableFilterKeys = {
  auth_token: [],
  // deleted_row: [],
  draft: ["thread_id", "user_id", "type"],
  inbox_entry: [],
  message: ["thread_id", "type"],
  message_reactions: ["thread_id"],
  notification: ["has_reminder", "is_done", "is_starred", "message_id", "remind_at", "tag_ids", "thread_id", "user_id"],
  organization_controlled_domain: ["domain", "organization_id"],
  organization_profile: [],
  organization_user_invitation: ["organization_id", "email_address"],
  organization_user_member: ["organization_id", "user_id"],
  organization: [],
  tag_folder_member: ["tag_id", "folder_id"],
  tag_group_member: ["tag_id", "group_id", "is_organization_group"],
  tag_subscription: ["user_id", "tag_id"],
  tag_user_member: ["tag_id", "user_id"],
  tag: [],
  tag_metadata: [],
  thread_group_permission: ["thread_id", "group_id"],
  thread_read_receipt: ["thread_id", "user_id"],
  thread_seen_receipt: ["thread_id", "user_id"],
  thread_subscription: ["user_id", "thread_id"],
  thread_tag: ["thread_id", "tag_id"],
  thread_timeline: ["entry_id", "thread_id"],
  thread_user_participant: ["thread_id", "user_id"],
  thread_user_permission: ["thread_id", "user_id"],
  thread: [],
  user_contact_info: [],
  user_lesson: ["user_id", "lesson_id"],
  user_oauth: ["user_id", "firebase_auth_id"],
  user_profile: [],
  user_settings: [],
  user: [],
  user_push_notification_subscriptions: ["user_id"],
} as const satisfies {
  readonly [Table in RecordTable]: ReadonlyArray<keyof RecordValue<Table>>;
};

export type TableHasFilterKeys = {
  [Table in RecordTable]: (typeof tableFilterKeys)[Table] extends readonly never[] ? never : Table;
}[RecordTable];

export type TableNoFilterKeys = {
  [Table in RecordTable]: (typeof tableFilterKeys)[Table] extends readonly never[] ? Table : never;
}[RecordTable];

export type TableFilterKey<T extends RecordTable> =
  // We need to help typescript realize that PKeys are always keyof the
  // associated record
  TableFilterKeyInner<T> extends keyof RecordValue<T> ? TableFilterKeyInner<T> : never;

type TableFilterKeyInner<T extends RecordTable> = (typeof tableFilterKeys)[T][number];

export type RecordFilterProps<T extends TableHasFilterKeys = TableHasFilterKeys> = Pick<
  RecordValue<T>,
  TableFilterKey<T>
>;

export const filterableTables = Object.keys(tablePKeys).filter(
  (table) => tableFilterKeys[table as RecordTable].length > 1,
) as TableHasFilterKeys[];

/* -------------------------------------------------------------------------------------------------
 * virtualTables
 * -----------------------------------------------------------------------------------------------*/

export const virtualTables: { readonly [table in RecordTable]?: boolean } = {
  inbox_entry: true,
};

/* -----------------------------------------------------------------------------------------------*/
