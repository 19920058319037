import { getCurrentUserId } from "./user.service";
import { ClientEnvironment } from "./ClientEnvironment";
import { MessagePortService, Request, SerializedError } from "./MessagePortService";
import { LocalForagePersistedKVStore } from "./KVStore";
import { filter, skip } from "rxjs";
import { DeferredPromise } from "libs/promise-utils";
import { Logger } from "libs/logger";
import { getContextFromLogEvent, sendLogToDatadog } from "./createClientLogger";
import { config } from "./config";

export class PersistedDatabaseWorkerService {
  private static broadcastChannel = new BroadcastChannel("persisted-database");

  static async setLastDatabaseUserId(userId: string | null) {
    if (userId) {
      await kvStore.setItem("lastDatabaseUserId", userId);
    } else {
      await kvStore.removeItem("lastDatabaseUserId");
    }
  }

  /**
   * The database will be cleared and reinstalled from scratch the next time an instance
   * of the PersistedDatabaseServiceProvider is promoted to leader. The PersistedDatabaseServiceProvider
   * _leader tab_ will also react to this action by telling all tabs to reload the page.
   * This reload will cause a new leader tab to be elected which will cause the persisted database schema to
   * be updated. It's necessary that all tabs reload because some running services (e.g. the SyncService)
   * might expect the persisted database cache to have certain records available which it will no longer have
   * after the reinstall.
   *
   * Note that reloading all tabs does require that the leader tab be initialized properly. If a bug has prevented
   * the leader tab from properly initializing, the behavior might be unexpected.
   */
  static reloadAllTabsAndReinstallCommsDatabase() {
    return setShouldMigrateCommsSchema(true);
  }

  readonly isActivePromise = new DeferredPromise<void>();

  private worker!: Worker;
  private connection: MessagePortService;

  constructor(
    protected env: Pick<ClientEnvironment, "logger" | "clientId" | "leader">,
    protected props: {
      onReinstallSchema: (env: { logger: Logger }) => Promise<void>;
    },
  ) {
    this.env = { ...env, logger: env.logger.child({ name: "PersistedDatabaseWorkerService" }) };

    this.connection = new MessagePortService({
      serviceName: "MainThreadPersistedDatabaseMessagePortService",
      senderId: env.clientId,
      logger: this.env.logger,
      defaultRetryOnDisconnect: false,
      getRecipient() {
        return this.recipients.get(MessagePortService.uniqueContexts.PERSISTED_DB_WORKER);
      },
    });

    this.connection.activated$.subscribe(() => {
      this.env.logger.notice(`Connection activated`);
      this.isActivePromise.resolve();
    });

    PersistedDatabaseWorkerService.broadcastChannel.onmessage = (event) => {
      if (event.data === "RELOAD_ALL_TABS") {
        location.reload();
      }
    };

    // Note that these events may be emitted as a result of changes in other tabs.
    kvStore
      .getItem$<boolean>("MIGRATE_COMMS_SCHEMA")
      // The curent value will be emitted immediately on subscription. We're only interested in changes
      // so we skip the first value.
      .pipe(skip(1))
      .subscribe(async (value) => {
        if (!value) return;
        if (!this.env.leader.isLeader) return;

        this.env.logger.info("[PersistedDatabaseService] MIGRATE_COMMS_SCHEMA change");

        await this.props.onReinstallSchema({ logger: this.env.logger });

        // In case there isn't a service worker update available, we reload all tabs.
        // It's necessary that all tabs reload because some running services (e.g. the SyncService)
        // might expect the persisted database cache to have certain records available which it
        // will no longer have after the reinstall.
        PersistedDatabaseWorkerService.broadcastChannel.postMessage("RELOAD_ALL_TABS");
        // Posting the message to the broadcast channel will notify other tabs but will not notify this
        // tab. We need to manually reload this tab as well.
        location.reload();
      });

    this.env.leader.isLeaderPromise
      .then(() => this.openDatabase())
      .catch((error) => {
        // We're not showing this alert, even though we'd like to, because it will freeze this tab.
        // If this code block was triggered after we attempted to reload all open tabs, that would
        // mean that every tab is initializing and this one happened to be elected the leader. By
        // showing this alert, other tabs would be requesting access to this tab (because this tab
        // is the leader tab) but would be blocked until the alert is dismissed. This would cause
        // the other tabs to fail to initialize. So instead we're just silently reloading every tab.
        //
        // alert(oneLine`
        //   An error occurred while opening the local Comms database. We're going to try
        //   clearing the local database and reloading Comms to see if that fixes the issue. If
        //   you continue to see this message, please reach out to team@comms.day.
        // `);

        // Because the error that was thrown might have been created in the worker thread and then
        // serialized and passed to this thread, we cannot rely on the error object to be an instance
        // of Error. We need to check what type of error it is by looking at the properties.
        const isSqliteError =
          error &&
          typeof error === "object" &&
          "name" in error &&
          "message" in error &&
          typeof error.name === "string" &&
          error.name.includes("SQLite3Error");

        this.env.logger.error(
          { error, isSqliteError },
          `[PersistedDatabaseService] Error opening database after promoting to leader`,
        );

        if (isSqliteError) {
          // Note that we also attempt to catch and handle errors inside the persisted database worker
          // during the init process. When successful, we can handle the error more gracefully within the
          // worker thread.
          PersistedDatabaseWorkerService.reloadAllTabsAndReinstallCommsDatabase();
        }
      });

    if (config.datadog.clientToken) {
      // Listen to logs from the persisted db worker and send them to datadog. Note that
      // we're subscribing to the messages stream rather than the more traditional requests
      // stream. This is because the requests stream buffers messages until the connection
      // has been activated. If a log error occurred which prevented the connection from
      // being activated, we wouldn't see the log message.
      this.connection._messages$
        .pipe(
          filter(
            (message): message is Request<"PERSISTED_DB_LOG"> =>
              !message.replyTo && message.type === "PERSISTED_DB_LOG",
          ),
        )
        .subscribe((request) => {
          const { level, logEvent } = request.data;
          const context = getContextFromLogEvent(logEvent);
          if (context.error) context.error = new SerializedError(context.error);
          sendLogToDatadog(level, context);
        });
    }
  }

  /**
   * Opens the database if it hasn't been opened yet. This method is idempotent.
   */
  private async openDatabase() {
    await navigator.locks.request(`open-persisted-db`, { ifAvailable: true }, async (lock) => {
      if (!lock) return;

      const currentUserId = getCurrentUserId();
      const lastDatabaseUserId = await getLastDatabaseUserId();

      // We don't do client-side authorization of queries so if the the database was last
      // used by another user it probably has records in it which the current user doesn't have
      // permission to access. Because of this, we need to clear the offline database on user changes.
      const wasDatabaseLastUsedByAnotherUser = !!lastDatabaseUserId && lastDatabaseUserId !== currentUserId;

      const clearOnInit = wasDatabaseLastUsedByAnotherUser || (await getShouldMigrateCommsSchema());

      this.worker = new Worker(new URL("./persisted-database-worker/start", import.meta.url), {
        name: "PersistedDatabaseWorker",
        type: "module",
      });

      this.connection.onConnect(MessagePortService.uniqueContexts.PERSISTED_DB_WORKER, this.worker);

      try {
        await this.activateDatabaseWorker({ clearOnInit });
      } catch (error) {
        this.env.logger.error(
          { error },
          "[PersistedDatabaseService] [openDatabase] Error activating persisted database worker",
        );

        throw error;
      }

      if (clearOnInit) {
        await setShouldMigrateCommsSchema(false);
      }

      await PersistedDatabaseWorkerService.setLastDatabaseUserId(currentUserId);

      this.connection.activate();
    });
  }

  ////////
  // Persisted DB requests
  //

  async getPortForSharedWorker() {
    await this.isActivePromise.promise;

    return this.connection.sendRequest("PERSISTED_DB_PORT", {
      from: this.env.clientId,
      to: MessagePortService.uniqueContexts.PERSISTED_DB_WORKER,
      data: { forClientId: MessagePortService.uniqueContexts.SHARED_WORKER },
    });
  }

  private activateDatabaseWorker(props: Request<"PERSISTED_DB_ACTIVATE">["data"]) {
    return this.connection.sendRequest("PERSISTED_DB_ACTIVATE", {
      to: MessagePortService.uniqueContexts.PERSISTED_DB_WORKER,
      data: props,
    });
  }
}

/* -----------------------------------------------------------------------------------------------*/

const kvStore = new LocalForagePersistedKVStore({ namespace: "PersistedDatabaseServiceProvider" });

function getLastDatabaseUserId() {
  return kvStore.getItem<string>("lastDatabaseUserId");
}

async function getShouldMigrateCommsSchema() {
  return !!(await kvStore.getItem("MIGRATE_COMMS_SCHEMA"));
}

/**
 * The constructor observes changes to this value and reacts. Note that the constructor
 * will see changes to this value from other tabs as well (which is desired).
 */
async function setShouldMigrateCommsSchema(value: boolean) {
  if (value) {
    await kvStore.setItem("MIGRATE_COMMS_SCHEMA", value);
  } else {
    await kvStore.removeItem("MIGRATE_COMMS_SCHEMA");
  }
}

/* -----------------------------------------------------------------------------------------------*/
