import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { op } from "libs/transaction";
import { getPointer } from "libs/schema";
import { toast } from "~/environment/toast-service";
import { ApiInput } from "libs/ApiTypes";
import { withTransaction, write } from "./write";
import { withPendingRequestBar } from "~/components/PendingRequestBar";

export type InviteUsersParams = ApiInput<"inviteUsers">;

export const inviteUsers = withPendingRequestBar(async function (
  environment: ClientEnvironment,
  params: InviteUsersParams,
) {
  const response = await environment.api.inviteUsers(params);

  if (response.status !== 200) return;

  environment.writeRecordMap(response.body.recordMap);
});

export const revokeUserInvitation = withPendingRequestBar(
  withTransaction(
    "revokeUserInvitation",
    async (
      environment,
      transaction,
      props: {
        /** Invitation id */
        id: string;
        email_address: string;
      },
    ) => {
      const isActionConfirmed = confirm(`Are you sure you want to revoke the invitation for ${props.email_address}?`);

      if (!isActionConfirmed) return;

      transaction.operations.push(
        op.update(getPointer("organization_user_invitation", props.id), {
          expires_at: op.fieldValue.SERVER_TIMESTAMP(),
        }),
      );

      try {
        await write(environment, {
          transaction,
          onOptimisticWrite: () => {
            toast("vanilla", {
              subject: "Revoking invitation...",
            });
          },
          onServerWrite: () => {
            toast("vanilla", {
              subject: "Invitation revoked.",
            });
          },
        });
      } catch (error) {
        toast("vanilla", {
          subject: "Failed to revoke invitation.",
        });

        throw error;
      }
    },
  ),
);

export const deleteUserInvitation = withPendingRequestBar(
  withTransaction(
    "deleteUserInvitation",
    async (
      environment,
      transaction,
      props: {
        /** Invitation id */
        id: string;
      },
    ) => {
      const [record] = await environment.recordLoader.getRecord("organization_user_invitation", props.id);

      if (!record?.expires_at) {
        environment.logger.error("[deleteUserInvitation] Only expired invitations can be deleted.");
        return;
      }

      transaction.operations.push(op.delete(getPointer("organization_user_invitation", record)));

      try {
        await write(environment, {
          transaction,
          onOptimisticWrite: () => {
            toast("vanilla", {
              subject: "Invitation deleted.",
            });
          },
        });
      } catch (error) {
        toast("vanilla", {
          subject: "Failed to delete invitation.",
        });

        throw error;
      }
    },
  ),
);
