import { ClientEnvironment } from "./ClientEnvironment";
import { config } from "./config";
import { UndoRedoStack } from "./UndoRedoStack";
import { startWith } from "libs/rxjs-operators";
import { combineLatest, distinctUntilChanged, filter, map, pairwise, switchMap } from "rxjs";
import { ServiceWorkerService } from "./service-worker/ServiceWorkerService";
import { initializeFocusService } from "./focus.service";
import { getUAParser, isPersistedDbSupported } from "~/utils/dom-helpers";
import { Logger } from "libs/logger";
import { PendingUpdates } from "./loading.service";
import { handleApiVersionError } from "~/utils/handleApiVersionError";
import { AuthService } from "./user.service";
import { createEnvironmentBase } from "./createEnvironmentBase";
import { SharedWorkerService, SharedWorkerServiceApi } from "./SharedWorkerService";
import { PersistedDatabaseServiceProvider } from "./PersistedDatabaseService";
import { LeadershipService } from "./LeadershipService";
import { PersistedDatabaseWorkerApi } from "./persisted-database-worker/PersistedDatabaseWorkerApi";
import { MessagePortService } from "./MessagePortService";
import { SyncServiceProvider } from "./shared-worker/SyncService";
import { getClientId } from "~/utils/getClientId";
import { KBarState } from "~/dialogs/kbar";
import { PersistedDatabaseWorkerService } from "./PersistedDatabaseWorkerService";
import { promiseTimeout } from "libs/promise-utils";
import { isEqual, isNonNullable } from "libs/predicates";
import { pick } from "lodash-comms";
import { datadogLogs } from "@datadog/browser-logs";

export async function createEnvironment(props: { logger: Logger; envLogger: Pick<Logger, "debug"> }) {
  const { logger, envLogger } = props;

  envLogger.debug({ config }, "Creating environment...");

  const auth = new AuthService({ logger });
  envLogger.debug({ auth }, "Created AuthService");

  const clientId = await getClientId();
  envLogger.debug({ clientId }, "Got clientId");

  // We acquire a context lock on our own clientId. Other tabs can watch this lock
  // to learn when this tab is closed.
  await MessagePortService.acquireContextLock(clientId);
  envLogger.debug({ clientId }, "Acquired clientId context lock");

  // Note that a side-effect of importing the ServiceWorkerService module is that we also
  // register the service worker and setup event listeners on it.
  const serviceWorker = new ServiceWorkerService({ logger });
  envLogger.debug({ serviceWorker }, "Created ServiceWorkerService");

  const leader = new LeadershipService({ logger, clientId });
  envLogger.debug({ leader }, "Created LeadershipService");

  let sharedWorker: SharedWorkerServiceApi | null = null;
  let persistedDb = null as PersistedDatabaseWorkerApi | null;
  let persistedDbWorker = null as PersistedDatabaseWorkerService | null;

  if (typeof SharedWorker !== "undefined") {
    sharedWorker = await SharedWorkerService.create(
      { logger, clientId, leader },
      { isPersistedDbSupported: isPersistedDbSupported() },
    );

    envLogger.debug({ sharedWorker }, "Created SharedWorkerService");

    persistedDbWorker = new PersistedDatabaseWorkerService(
      { logger, clientId, leader },
      {
        onReinstallSchema: async ({ logger }) => {
          try {
            // This will check for a service worker update and, if available, install and activate
            // the update. This will cause all pages to reload with the latest version of the client,
            // if successful. It's very important we do this *before* attempting to reinstall the Comms
            // database so that we have the latest version of the db schema.
            await serviceWorker.checkForUpdateAndActivateWhenAvailable();
          } catch (error) {
            logger.error({ error }, "[onReinstallSchema] checkForUpdateAndActivateIfAvailable");
            await auth.signout();
          }
        },
      },
    );

    envLogger.debug({ persistedDbWorker }, "Created PersistedDatabaseWorkerService");

    persistedDb = PersistedDatabaseServiceProvider.createService({
      logger,
      sharedWorker,
      persistedDbWorker,
    });

    envLogger.debug({ persistedDb }, "Created PersistedDatabaseService");

    // We want to activate the shared worker after the services which depend on it are activated.
    // Delaying activation causes pending messages sent through the sharedWorker to be buffered
    // and allows all the services which depend on it to be ready to receive messages.
    await sharedWorker.activate();
    envLogger.debug("Activated shared worker");
  } else {
    envLogger.debug("Skipping SharedWorkerService. Not supported.");
    const parser = getUAParser();
    envLogger.debug({ device: parser.getDevice() }, "Skipping PersistedDatabaseService");
  }

  const environmentBase = await createEnvironmentBase({
    logger,
    envLogger,
    auth,
    persistedDb,
    clientId,
    subscribeToAlwaysAvailableRecordsFetchStrategy: "cache",
    onApiAuthenticationError: () => {
      return auth.signout({ force: true });
    },
    onApiVersionError: (error) => {
      return handleApiVersionError(environment, error);
    },
  });

  const undoRedo = new UndoRedoStack();
  envLogger.debug({ undoRedo }, "Created UndoRedoStack");

  const environment: ClientEnvironment = {
    ...environmentBase,
    auth,
    clientId,
    leader,
    logger,
    persistedDb,
    persistedDbWorker,
    serviceWorker,
    sharedWorker,
    undoRedo,
  };

  initializeFocusService(logger);

  if (persistedDb) {
    let isErrored = false;

    promiseTimeout(
      15_000,
      persistedDb.getSchemaVersion().then((version) => {
        environment.info.version.schema.actual = version;

        if (isErrored) {
          logger.warn("Eventually got persisted db schema version");
        }
      }),
    ).catch((error) => {
      logger.error({ error }, "Failed to get persisted db schema version");
      isErrored = true;
    });
  }

  // Log user ID changes and handle logout events.
  auth.currentUserId$.pipe(startWith(auth.getCurrentUserId), pairwise()).subscribe(([prevUserId, currentUserId]) => {
    logger.info({ currentUserId }, `CURRENT_USER_ID`);

    environment.info.currentUserId = currentUserId;
    sharedWorker?.onAuthChange();

    if (prevUserId && !currentUserId) {
      // If the user logs out on another tab, we want to reload this tab to reset the environment.
      // We clear pending updates so that we don't warn the user about unsaved changes (which might
      // prevent the page from being reloaded).
      PendingUpdates.clearAll();
      window.location.href = "/login";
    }
  });

  // Looking at the production logs, it seems like it's possible some users have an invalid owner_organization_id
  // Cookie. The only place that I think a bug like this could reside would be the createUser logic. In some
  // scenarios, the ownerOrganizationId value associated with someone's login can change after they create an
  // account for the first time. We already migrated the ownerOrganizationId cookie in this case, but just to be
  // safe I refactored the logic to be safer by expiring any existing cookies when their account is created.
  // If any existing users were effected by an unknown bug here,
  // I don't see any issues there but, to be safe, I refactored the createUser logic
  // Looking at the `createUser` logic, it seems possible that someone might have an invalid ownerOrganizationId
  // value in certain scenerios after creating an account (this would only last until the user logs out). I've fixed that
  // potential issue. Here we are checking if the user's ownerOrganizationId is valid and signing them out of not.
  // It's possible that we can remove this code in the future.
  // -- John 10/8/24
  auth.currentUserId$
    .pipe(
      filter(isNonNullable),
      switchMap((currentUserId) =>
        combineLatest([
          environment.recordLoader.observeGetRecord("user_profile", currentUserId, { fetchStrategy: "cache-first" }),
          auth.currentUserOwnerOrganizationId$,
        ]),
      ),
      // We refetch the ownerOrganizationId (rather than using the emitted value) since there can be a slight delay
      // between auth change and the currentUserOwnerOrganizationId$ observable emitting.
      map(([[record]]) => [record?.owner_organization_id, auth.getCurrentUserOwnerOrganizationId()] as const),
      distinctUntilChanged(isEqual),
    )
    .subscribe(([expectedOwnerOrganizationId, cookieOwnerOrganizationId]) => {
      if (!expectedOwnerOrganizationId) return;
      if (expectedOwnerOrganizationId === cookieOwnerOrganizationId) return;
      auth.signout();
    });

  // Set the user context for Datadog logs.
  auth.currentUserId$
    .pipe(
      filter(isNonNullable),
      switchMap((currentUserId) =>
        environment.recordLoader.observeGetRecord("user_profile", currentUserId, { fetchStrategy: "cache-first" }),
      ),
      map(([record]) => pick(record, ["id", "name"])),
      distinctUntilChanged(isEqual),
    )
    .subscribe(({ id, name }) => {
      if (!id) return;
      datadogLogs.setUser({ id, name });
    });

  // When a user creates an account for the first time, their ownerOrganizationId value might change.
  auth.currentUserOwnerOrganizationId$.subscribe((currentOrgId) => {
    logger.info({ currentOrgId }, `current user's owner organization ${currentOrgId}`);
    sharedWorker?.onAuthChange();
  });

  KBarState.init();
  PendingUpdates.init(environment);

  // This exists to facilitate e2e testing.
  // See the e2e Page dialect's `waitForSyncServiceInitialSyncToComplete` method for how this is used.
  if (config.mode === "test") {
    (environment as any).isInitialSyncComplete$ = SyncServiceProvider.isInitialSyncComplete$;
  }

  envLogger.debug({ environment }, "Created environment");

  return environment;
}
