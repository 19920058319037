import { SetNonNullable } from "libs/type-helpers";
import { ClientEnvironment } from "./ClientEnvironment";
import { MessagePortService, Request } from "./MessagePortService";
import { PersistedDatabaseWorkerApi } from "./persisted-database-worker/PersistedDatabaseWorkerApi";

export type PersistedDatabaseServiceProviderEnv = SetNonNullable<
  Pick<ClientEnvironment, "logger" | "sharedWorker" | "persistedDbWorker">,
  "persistedDbWorker" | "sharedWorker"
>;

export class PersistedDatabaseServiceProvider {
  static createService(env: PersistedDatabaseServiceProviderEnv) {
    const provider = new PersistedDatabaseServiceProvider(env);
    const service = provider.createService();
    return service;
  }

  private constructor(protected env: PersistedDatabaseServiceProviderEnv) {
    // When the persisted database worker is actived then we want to send a port to the shared worker.
    env.persistedDbWorker.isActivePromise.promise.then(async () => {
      const { port } = await env.persistedDbWorker.getPortForSharedWorker();
      this.env.sharedWorker.onPersistedDatabaseActive({ port });
    });
  }

  createService() {
    return new Proxy({} as PersistedDatabaseWorkerApi, {
      get: (_, prop: string) => {
        if (prop === "then") return undefined;
        if (prop === "toJSON") {
          return () => Promise.reject(new Error("Method toJSON called on PersistedDatabaseService"));
        }

        return (...args: any[]) => {
          if (prop.startsWith("observe")) {
            return this.observeQuery({ prop, args });
          }

          return this.getQuery({ prop, args });
        };
      },
    });
  }

  private getQuery(props: Request<"PERSISTED_DB_QUERY">["data"]) {
    return this.env.sharedWorker.connection.sendRequest(
      "PERSISTED_DB_QUERY",
      {
        to: MessagePortService.uniqueContexts.PERSISTED_DB_WORKER,
        data: props,
      },
      { retryOnDisconnect: true },
    );
  }

  private observeQuery(props: Request<"PERSISTED_DB_OBSERVE_QUERY">["data"]) {
    return this.env.sharedWorker.connection.observeRequest(
      "PERSISTED_DB_OBSERVE_QUERY",
      {
        to: MessagePortService.uniqueContexts.PERSISTED_DB_WORKER,
        data: props,
      },
      { retryOnDisconnect: true },
    );
  }
}

/* -----------------------------------------------------------------------------------------------*/
