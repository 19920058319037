import { wait } from "libs/promise-utils";
import { updatableBehaviorSubject } from "libs/updatableBehaviorSubject";
import { uuid } from "libs/uuid";
import { useObservableState } from "observable-hooks";
import { BehaviorSubject, combineLatest, filter, firstValueFrom, fromEvent, map, NEVER, switchMap } from "rxjs";
import { config } from "./config";
import { ClientEnvironment } from "./ClientEnvironment";

export const IS_LOADING$ = new BehaviorSubject(false);

/**
 * When called with `setIsLoading(true)`, this covers the screen with
 * a loading indicator modal that prevents user interaction until
 * loading is marked complete `setIsLoading(false)`.
 */
export function setIsLoading(value: boolean): void;
/**
 * This provides a higher order function which wraps any function
 * that returns a promise. The new function behaves identically
 * to the original except that, when it is called and while the returned
 * promise is pending, a loading indicator modal will automatically
 * be added to the screen and then automatically removed when the
 * promise is resolved.
 */
// don't know how to type this generic function without `any`

export function setIsLoading<T extends (...args: any[]) => Promise<any>>(value: T): T;
/**
 * Automatically adds a loading indicator modal on top of other elements
 * which is automatically removed with the promise resolves.
 */
export function setIsLoading<T extends Promise<unknown>>(value: T): T;
export function setIsLoading(
  // don't know how to type this generic function without `any`

  value: boolean | Promise<unknown> | ((...args: any[]) => Promise<any>),
) {
  if (value instanceof Promise) {
    IS_LOADING$.next(true);
    return value.finally(() => IS_LOADING$.next(false));
  }

  if (value instanceof Function) {
    return (...args: unknown[]) => {
      IS_LOADING$.next(true);
      return value(...args).finally(() => IS_LOADING$.next(false));
    };
  }

  IS_LOADING$.next(value);
}

const PENDING_UPDATES_STORE$ = updatableBehaviorSubject(new Set<string>());

export const PendingUpdates = {
  value() {
    const currentValue = PENDING_UPDATES_STORE$.getValue();
    return currentValue.size > 0;
  },

  value$: PENDING_UPDATES_STORE$.pipe(map((set) => set.size > 0)),
  /**
   * Indicates that an update is pending and associates that update
   * with a specific key. Returns a callback function that, when
   * called, will indicate that the update is no longer pending.
   */
  add(_key?: string) {
    const key = _key || uuid();
    PENDING_UPDATES_STORE$.update((set) => new Set(set).add(key));

    return () => {
      PENDING_UPDATES_STORE$.update((set) => {
        set = new Set(set);
        set.delete(key);
        return set;
      });
    };
  },

  /**
   * In general, it is preferrable to use the callback returned by
   * `PendingUpdates.add()` instead of this method to signal
   * a pending update has completed.
   */
  remove(key: string) {
    PENDING_UPDATES_STORE$.update((set) => {
      set = new Set(set);
      set.delete(key);
      return set;
    });
  },

  clearAll() {
    PENDING_UPDATES_STORE$.update(() => new Set());
  },

  /** Returns a promise which resolves when there are no pending updates */
  async whenNoPendingUpdates(
    props: {
      /**
       * This option is intended to be used with important programmatic page reloads/redirects.
       * Normally Comms shows the user a warning if they have any pending updates when the user
       * attempts to close or navigate away from the current page. This option clears any
       * remaining pending update flags after the specified number of ms. Note that the pending
       * updates still exist in this case, but the user will not be warned about them and they
       * will not prevent Comms from reloading the page.
       */
      forcePendingUpdatesToClearAfterMs?: number;
    } = {},
  ) {
    const promise = firstValueFrom(PendingUpdates.value$.pipe(filter((v) => !v)));

    if (props.forcePendingUpdatesToClearAfterMs) {
      const timeout = wait(props.forcePendingUpdatesToClearAfterMs);

      await Promise.race([promise, timeout]).then(() => {
        if (PendingUpdates.value()) {
          PendingUpdates.clearAll();
        }
      });
    } else {
      await promise;
    }
  },
  init(environment: Pick<ClientEnvironment, "network">) {
    const WINDOW_UNLOAD_EVENTS$ = fromEvent<BeforeUnloadEvent>(window, "beforeunload", {
      capture: true,
    });

    // Here we show the user a warning if they have uncommitted pending
    // updates with the server. Note, I think on every modern browser
    // the message we provide will be ignored and they'll just say
    // something like, "You have unsaved changes." But that's the best
    // we've got.
    //
    // Taken from
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#examples
    combineLatest([PendingUpdates.value$, environment.network.isOnline$])
      .pipe(
        switchMap(([hasPendingUpdates, isOnline]) => (!hasPendingUpdates || !isOnline ? NEVER : WINDOW_UNLOAD_EVENTS$)),
      )
      .subscribe((e) => {
        e.preventDefault();
        e.returnValue = "Are you sure? You have changes still being uploaded to the server.";
      });
  },
} as const;

/**
 * This wraps a promise and
 * marks the comms app as "pending" until the promise resolves. If the
 * app is "pending", a user attempting to close the tab will be warned
 * and there is also a subtle loading spinner in the corner.
 */

export function withPendingUpdate<T>(promise: Promise<T>): Promise<T>;
/**
 * This higher order function wraps an async function and, when called,
 * marks the comms app as "pending" until the promise resolves. If the
 * app is "pending", a user attempting to close the tab will be warned
 * and there is also a subtle loading spinner in the corner.
 */

export function withPendingUpdate<T extends (...args: any[]) => Promise<any>>(fn: T): T;
export function withPendingUpdate(fnOrPromise: ((...args: unknown[]) => Promise<unknown>) | Promise<unknown>) {
  if (fnOrPromise instanceof Promise) {
    const onComplete = PendingUpdates.add();
    return fnOrPromise.finally(onComplete);
  }

  return (...args: unknown[]) => {
    const onComplete = PendingUpdates.add();
    return fnOrPromise(...args).finally(onComplete);
  };
}

export function usePendingUpdates() {
  return useObservableState(() => PendingUpdates.value$, PendingUpdates.value);
}

if (config.mode !== "test") {
  // TODO:
  // remove this when we determine why sometimes pending updates
  // don't seem to resolve.
  PENDING_UPDATES_STORE$.subscribe((store) => {
    console.debug("Pending updates", new Set(store));
  });
}
