import { ApiInput } from "libs/ApiTypes";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import * as ops from "libs/actions";
import { withTransaction, write } from "./write";
import { toast } from "~/environment/toast-service";
import { withPendingRequestBar } from "~/components/PendingRequestBar";

export type CreateUserParams = ApiInput<"createUser">;

/**
 * Note that this is not an "optimistic" operation. We wait for it
 * to be confirmed by the server. Also, we don't support creating
 * users while offline.
 */
export const createUser = withPendingRequestBar(async function (
  environment: ClientEnvironment,
  params: CreateUserParams,
) {
  const { status, body } = await environment.api.createUser(params);

  if (status !== 200) return;
  if (!body.recordMap) return;

  environment.db.writeRecordMap(body.recordMap);
});

export type UpdateUserProps = {
  userId: string;
  profile?: Omit<ops.user.UpdateUserNameProps, "userId">;
  contactInfo?: Omit<ops.user.UpdateUserContactInfoProps, "userId">;
};

export const updateUser = withPendingRequestBar(
  withTransaction("updateUser", async (environment, transaction, props: UpdateUserProps) => {
    const { profile, contactInfo } = props;

    if (profile) {
      ops.applyOperationsToTransaction(
        transaction,
        ops.user.updateUserName({
          userId: props.userId,
          ...profile,
        }),
      );
    }

    if (contactInfo) {
      ops.applyOperationsToTransaction(
        transaction,
        ops.user.updateUserContactInfo({
          userId: props.userId,
          ...contactInfo,
        }),
      );
    }

    await write(environment, {
      transaction,
      onOptimisticUndo: () => {
        toast("vanilla", { subject: "Undoing user update." });
      },
    });
  }),
);
