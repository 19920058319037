import { wait } from "libs/promise-utils";
import { create } from "zustand";
import { PersistedDatabaseWorkerService } from "~/environment/PersistedDatabaseWorkerService";

/** Global app state */
export const globalState = create<{
  showIsLoading: boolean;
  errorMessage: string | null;
  setErrorMessage: (msg: string, options?: { forcePageRefresh?: boolean }) => void;
}>((set) => ({
  showIsLoading: false,
  errorMessage: null,
  setErrorMessage: (msg, options) => {
    if (options?.forcePageRefresh) {
      set({
        showIsLoading: true,
        errorMessage: msg,
      });

      PersistedDatabaseWorkerService.reloadAllTabsAndReinstallCommsDatabase().finally(async () => {
        // Wait to give time for Comms to handle the call to reloadAllTabsAndReinstallCommsDatabase.
        await wait(6000);

        // In case the above command fails because of a bug, we tell this page to reload.
        window.location.reload();
      });
    } else {
      set({ errorMessage: msg });
    }
  },
}));
