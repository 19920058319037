import { LogEvent, LogLevel, LoggerOptions, createLogger } from "libs/logger";
import { StatusType, datadogLogs } from "@datadog/browser-logs";
import { config } from "./config";
import { omit } from "lodash-comms";

export function createClientLogger(options: LoggerOptions = {}) {
  let logger = createLogger({
    enabled: config.mode !== "test",
    browser: {
      asObject: true,
      disabled: config.mode === "test",
      // There's a current bug with the pino "level" option that prevents it from working.
      // Here we manully filter out logs based on the level.
      // See https://github.com/pinojs/pino/issues/960
      write: {
        verbose: (o: any) => {
          if (logger.levelVal > 5) return;
          console.debug(getFormattedLogMsg(o), o);
        },
        trace: (o: any) => {
          if (logger.levelVal > 10) return;
          console.trace(getFormattedLogMsg(o), o);
        },
        debug: (o: any) => {
          if (logger.levelVal > 20) return;
          console.debug(getFormattedLogMsg(o), o);
        },
        info: (o: any) => {
          if (logger.levelVal > 30) return;
          console.info(getFormattedLogMsg(o), o);
        },
        notice: (o: any) => {
          if (logger.levelVal > 35) return;
          console.info(getFormattedLogMsg(o), o);
        },
        warn: (o: any) => {
          if (logger.levelVal > 40) return;
          console.warn(getFormattedLogMsg(o), o);
        },
        error: (o: any) => {
          if (logger.levelVal > 40) return;
          console.error(getFormattedLogMsg(o), o);
        },
        fatal: (o: any) => {
          if (logger.levelVal > 40) return;
          console.error(getFormattedLogMsg(o), o);
        },
      },
      ...options.browser,
    },
    ...omit(options, "browser"),
  });

  if (options.name) {
    // For whatever reason, Pino seems to be ignoring the name prop otherwise
    logger = logger.child({ name: options.name });
  }

  return logger;
}

export function sendLogToDatadog(level: LogLevel, context: any) {
  let status: StatusType;

  if (level === "verbose") status = "debug";
  else if (level === "trace") status = "debug";
  else if (level === "fatal") status = "critical";
  else status = level;

  datadogLogs.logger.log(context.msg, context, status, context.error || context.err);
}

export function getContextFromLogEvent(logEvent: LogEvent) {
  const data = [...logEvent.bindings, ...logEvent.messages];

  const context = data.reduce(
    (store, data) => (typeof data === "object" ? { ...store, ...data } : { ...store, msg: data }),
    {},
  );

  // If there's a message/msg prop on the context, it might take precedence over the log message
  // in datadog. As such, either we need to remove the props from the context or the context's message
  // prop and the log message need to be the same.
  context.msg = getFormattedLogMsg(context);
  delete context.message;

  return omit(context, "environment");
}

function getFormattedLogMsg(o: any) {
  let message = o.message || o.msg || "";
  const prefix = `[${o.name}]`;

  if (typeof message !== "string" || !message) {
    message = prefix;
  } else if (!message.startsWith(prefix)) {
    message = `${prefix} ${message}`;
  }

  return message as string;
}

export function getLogLevel() {
  if (typeof localStorage === "undefined") return undefined;
  return localStorage.getItem("comms:log-level") || undefined;
}

export function setLogLevel(level: string | null) {
  if (level && !["verbose", "trace", "debug", "info", "warn", "error", "fatal"].includes(level)) {
    throw new Error(`Invalid log level: ${level}. Must be one of: verbose, trace, debug, info, warn, error, fatal`);
  }

  if (level) {
    localStorage.setItem("comms:log-level", level);
  } else {
    localStorage.removeItem("comms:log-level");
  }

  console.warn(`Log level set to ${level || "default"}. You'll need to reload the page for this to take effect.`);
}
