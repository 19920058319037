import Pino from "pino";
import { Simplify } from "type-fest";

export type Logger = ReturnType<typeof createLogger>;
export type LoggerOptions = Pino.LoggerOptions<"notice" | "verbose">;
export type LogLevel = Simplify<Pino.Level | "notice" | "verbose">;
export type LogEvent = Simplify<Pino.LogEvent>;

export function createLogger(options: LoggerOptions, transport?: Pino.DestinationStream) {
  return Pino(
    {
      customLevels: {
        notice: 35,
        verbose: 5,
      },
      formatters: {
        level: (label) => ({ level: label }),
      },
      serializers: {
        err: serializeErrorWithCause,
        error: serializeErrorWithCause,
        errors: (errors: unknown) => {
          const serializeError = (error: unknown) => (error instanceof Error ? serializeErrorWithCause(error) : error);

          if (!Array.isArray(errors)) {
            return serializeError(errors);
          }

          return errors.map(serializeError);
        },
      },
      ...options,
    },
    transport,
  );
}

// Pino's "errWithCause" implementation doesn't work correctly in the browser.
// See https://github.com/pinojs/pino/issues/2057
function serializeErrorWithCause(err: any, seen = new WeakMap()): any {
  if (typeof err !== "object" || err === null) return err;
  if (seen.has(err)) return seen.get(err);

  const obj: any = {};

  seen.set(err, obj);

  obj.type = err.constructor.name;
  obj.msg = err.message;
  obj.stack = err.stack;
  obj.cause = serializeErrorWithCause(err.cause, seen);

  for (const key in err) {
    if (obj[key] !== undefined) continue;
    obj[key] = err[key];
  }

  return obj;
}
