import { DeferredPromise } from "libs/promise-utils";
import { ClientEnvironment } from "./ClientEnvironment";
import { MessagePortService } from "./MessagePortService";

export class LeadershipService {
  get isLeader() {
    return this.deferredIsLeader.settled;
  }

  get isLeaderPromise() {
    return this.deferredIsLeader.promise;
  }

  /** A deferred promise that is resolved when this tab is promoted to leader */
  private deferredIsLeader = new DeferredPromise<void>();

  constructor(protected env: Pick<ClientEnvironment, "logger" | "clientId">) {
    this.env = {
      ...env,
      logger: env.logger.child({ name: "LeadershipService" }),
    };

    MessagePortService.acquireContextLock(MessagePortService.uniqueContexts.LEADER).then(() => {
      this.deferredIsLeader.resolve();
    });

    this.isLeaderPromise.then(() => {
      // If promotion happens during env creation than the datadog logger will not be able to
      // automatically add the client ID to this log. So we manually add it here.
      this.env.logger.notice({ clientId: this.env.clientId }, `promoted to leader`);
    });
  }
}

/* -----------------------------------------------------------------------------------------------*/
