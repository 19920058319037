import { createClientLogger, getContextFromLogEvent, getLogLevel, sendLogToDatadog } from "./createClientLogger";

if (typeof document === "undefined") {
  throw new Error("[logger.ts] This module is only intended to be imported and used by the client's main thread");
}

export const logger = createClientLogger({
  name: "MainThread",
  level: getLogLevel() ?? "debug",
  browser: {
    transmit: {
      level: "notice",
      send(level, logEvent) {
        if (logEvent.level.value <= 30) return;

        try {
          const context = getContextFromLogEvent(logEvent);
          sendLogToDatadog(level, context);
        } catch (error) {
          console.warn("Failed to send log to Datadog", error);
        }
      },
    },
  },
});
