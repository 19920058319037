declare const self: SharedWorkerGlobalScope | DedicatedWorkerGlobalScope;

export function isSharedWorker(self: any): self is SharedWorkerGlobalScope {
  return "onconnect" in self;
}

export type SharedWorkerEnvironmentInitData = {
  persistedDb: boolean;
  log: string | undefined;
};

let workerData: SharedWorkerEnvironmentInitData | undefined | null;

export function getWorkerData() {
  if (workerData === undefined) {
    const json = self.name.slice(self.name.indexOf("-") + 1);

    try {
      workerData = JSON.parse(json) ?? null;
    } catch (e) {
      workerData = null;
      console.error("[SharedWorkerEnvironment] Failed to parse worker data", e);
    }
  }

  return workerData;
}

export function createWorkerData(props: SharedWorkerEnvironmentInitData) {
  return JSON.stringify(props);
}

/* -----------------------------------------------------------------------------------------------*/

export function isPersistedDbSupported() {
  return getWorkerData()?.persistedDb ?? false;
}

/* -----------------------------------------------------------------------------------------------*/
