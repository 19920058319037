import { config } from "~/environment/config";

/**
 * A banner that displays when the app is in beta mode.
 */
export function BetaBanner() {
  return (
    <>{config.isBeta && <div className="bg-red-9 text-center text-white font-bold text-sm py-1">Beta Version</div>}</>
  );
}
