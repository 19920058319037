import { getCurrentUserId, getCurrentUserOwnerOrganizationId } from "./user.service";
import { Simplify } from "type-fest";
import { ClientEnvironment } from "./ClientEnvironment";
import { datadogLogs } from "@datadog/browser-logs";
import { MessagePortService, Request, SerializedError } from "./MessagePortService";
import { getContextFromLogEvent, getLogLevel, sendLogToDatadog } from "./createClientLogger";
import { createWorkerData } from "./shared-worker/utils";
import { config } from "./config";
import { filter } from "rxjs";
import { LogEvent, LogLevel } from "libs/logger";
import { wait } from "libs/promise-utils";

declare module "./MessagePortService" {
  interface MessageTypeMap {
    SHARED_WORKER_CONNECT: {
      request: Message<
        "SHARED_WORKER_CONNECT",
        {
          currentUserId: string | null;
          ownerOrganizationId: string | null;
          datadogSessionId: string | null;
        }
      >;
      response: Message<"SHARED_WORKER_CONNECT", null>;
    };
    SHARED_WORKER_LOG: {
      request: Message<"SHARED_WORKER_LOG", { level: LogLevel; logEvent: LogEvent }>;
      response: Message<"SHARED_WORKER_LOG", null>;
    };
    SHARED_WORKER_PERSISTED_DB_PORT: {
      request: Message<"SHARED_WORKER_PERSISTED_DB_PORT", { port: MessagePort }>;
      response: Message<"SHARED_WORKER_PERSISTED_DB_PORT", null>;
    };
  }
}

/**
 * The `ServiceWorkerService` class is responsible for managing the service worker for the app.
 */

export type SharedWorkerServiceApi = Simplify<SharedWorkerService>;
export type SharedWorkerServiceEnv = Pick<
  ClientEnvironment,
  | "logger"
  | "clientId"
  // Note that it's important that we attempt to acquire a lock
  // on leadership before initializing the shared worker. This way the SharedWorkerLeadershipService can
  // look up who holds the leadership lock during initialization. A good way to enforce this order is to
  // make this service depend on the leader service.
  | "leader"
>;

export class SharedWorkerService {
  static async create(env: SharedWorkerServiceEnv, props: { isPersistedDbSupported: boolean }) {
    const service = new SharedWorkerService(env, props);
    await service.init();
    return service;
  }

  connection: MessagePortService;

  private worker!: SharedWorker | Worker;

  constructor(
    private env: SharedWorkerServiceEnv,
    private props: { isPersistedDbSupported: boolean },
  ) {
    this.env = {
      ...env,
      logger: env.logger.child({ name: "SharedWorkerService" }),
    };

    this.connection = new MessagePortService({
      serviceName: "SharedWorkerService",
      senderId: env.clientId,
      logger: env.logger,
      defaultRetryOnDisconnect: true,
      getRecipient() {
        return this.recipients.get(MessagePortService.uniqueContexts.SHARED_WORKER);
      },
    });

    if (config.datadog.clientToken) {
      // Listen to logs from the shared worker and send them to datadog. Note that
      // we're subscribing to the messages stream rather than the more traditional requests
      // stream. This is because the requests stream buffers messages until the connection
      // has been activated. If a log error occurred which prevented the connection from
      // being activated, we wouldn't see the log message.
      this.connection._messages$
        .pipe(
          filter(
            (message): message is Request<"SHARED_WORKER_LOG"> =>
              !message.replyTo && message.type === "SHARED_WORKER_LOG",
          ),
        )
        .subscribe((request) => {
          const { level, logEvent } = request.data;
          const context = getContextFromLogEvent(logEvent);
          if (context.error) context.error = new SerializedError(context.error);
          sendLogToDatadog(level, context);
        });
    }
  }

  onAuthChange() {
    return this.sendAuthChange();
  }

  onPersistedDatabaseActive(props: Request<"SHARED_WORKER_PERSISTED_DB_PORT">["data"]) {
    this.env.logger.notice(`Sending persisted db port to shared worker`);
    return this.sendPersistedDbPort(props);
  }

  async init() {
    await this.createSharedWorker();
    this.watchForSharedWorkerRestarts();
  }

  async createSharedWorker() {
    this.worker = new SharedWorker(new URL("./shared-worker", import.meta.url), {
      // We pass some data to the worker synchronously via the name.
      // Vite requires passing vite-ignore if the worker name is dynamic. Not sure why, but this is
      // vite's suggested workaround.
      /* @vite-ignore */
      name: `CommsSharedWorker-${this.getWorkerData()}`,
      type: "module",
    });

    const watchForDisconnect = this.connection.onConnect(
      MessagePortService.uniqueContexts.SHARED_WORKER,
      this.worker.port,
    );

    await this.sendSharedWorkerConnect();

    watchForDisconnect();

    this.env.logger.debug(`Shared worker initialized and connected`);
  }

  async activate() {
    this.connection.activate();
  }

  private sendAuthChange() {
    return this.connection.sendRequest("AUTH_CHANGE", {
      to: MessagePortService.uniqueContexts.SHARED_WORKER,
      data: {
        currentUserId: getCurrentUserId(),
        ownerOrganizationId: getCurrentUserOwnerOrganizationId(),
      },
    });
  }

  private sendSharedWorkerConnect() {
    return this.connection.sendRequest("SHARED_WORKER_CONNECT", {
      to: MessagePortService.uniqueContexts.SHARED_WORKER,
      data: {
        currentUserId: getCurrentUserId(),
        ownerOrganizationId: getCurrentUserOwnerOrganizationId(),
        datadogSessionId: datadogLogs.getInternalContext()?.session_id ?? null,
      },
    });
  }

  private sendPersistedDbPort(props: Request<"SHARED_WORKER_PERSISTED_DB_PORT">["data"]) {
    return this.connection.sendRequest("SHARED_WORKER_PERSISTED_DB_PORT", {
      to: MessagePortService.uniqueContexts.SHARED_WORKER,
      data: props,
      transfer: [props.port],
    });
  }

  private async watchForSharedWorkerRestarts() {
    while (true) {
      await navigator.locks.request(MessagePortService.uniqueContexts.SHARED_WORKER, { mode: "shared" }, () => {
        return this.createSharedWorker();
      });

      await wait(5);
    }
  }

  private getWorkerData() {
    return createWorkerData({
      persistedDb: this.props.isPersistedDbSupported,
      log: getLogLevel(),
    });
  }
}

/* -----------------------------------------------------------------------------------------------*/
