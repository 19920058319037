import { Observable, distinctUntilChanged, fromEvent, map, merge, share, shareReplay, throttleTime } from "rxjs";
import { startWith } from "libs/rxjs-operators";
import { DeferredPromise } from "libs/promise-utils";
import { generateRecordId } from "libs/schema";
import { isNativeIOS } from "./pwaBuilder-utils";
import UAParser from "ua-parser-js";

/**
 * Sorts DOM nodes based on their relative position in the DOM.
 */
// See https://developer.mozilla.org/en-US/docs/Web/API/Node/compareDocumentPosition
export function domNodeComparer(a: Node, b: Node) {
  const compare = a.compareDocumentPosition(b);

  if (compare & Node.DOCUMENT_POSITION_FOLLOWING || compare & Node.DOCUMENT_POSITION_CONTAINED_BY) {
    // a < b
    return -1;
  }

  if (compare & Node.DOCUMENT_POSITION_PRECEDING || compare & Node.DOCUMENT_POSITION_CONTAINS) {
    // a > b
    return 1;
  }

  if (compare & Node.DOCUMENT_POSITION_DISCONNECTED || compare & Node.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC) {
    throw Error("unsortable");
  } else {
    return 0;
  }
}

export function getMaxScrollTop(el: HTMLElement) {
  if (el === document.body) {
    const html = document.documentElement;

    const maxScrollTop =
      Math.max(el.scrollHeight, el.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight) -
      html.clientHeight;

    return maxScrollTop;
  }

  return el.scrollHeight - el.clientHeight;
}

/** If passed the body element, returns document.documentElement.scrollTop instead */
export function getScrollTop(el: Element) {
  return el === document.body ? document.documentElement.scrollTop : el.scrollTop;
}

/** If passed the body element, sets document.documentElement.scrollTop instead */
export function setScrollTop(el: Element, value: number | ((oldValue: number) => number)) {
  const normalizedEl = el === document.body ? document.documentElement : el;

  const newValue = typeof value === "function" ? value(normalizedEl.scrollTop) : value;

  normalizedEl.scrollTop = newValue;
}

/** If passed the body element, scrolls document.documentElement instead */
export function scrollElementTo(el: HTMLElement, options?: ScrollToOptions | undefined) {
  if (el === document.body) {
    document.documentElement.scrollTo(options);
  } else {
    el.scrollTo(options);
  }
}

export function observeFocusWithin(...elements: HTMLElement[]) {
  return merge(
    ...elements.map((el) => fromEvent<FocusEvent>(el, "focusin").pipe(map(() => true))),
    ...elements.map((el) =>
      fromEvent<FocusEvent>(el, "focusout").pipe(
        map((e) => elements.some((el) => el.contains(e.relatedTarget as Node | null))),
      ),
    ),
  ).pipe(
    startWith(() => elements.some((el) => el.matches(":focus-within"))),
    distinctUntilChanged(),
  );
}

/**
 * Copy a value to the clipboard. If the clipboard API isn't
 * supported, this will throw an error.
 * @returns promise which resolves when the copy is complete
 */
export function writeToClipboard<T extends BlobPart>(args: {
  /**
   * These are the only types supported by Firefox.
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/write
   */
  type: "text/plain" | "text/html" | "image/png";
  value: T;
}) {
  if (!("clipboard" in navigator)) {
    throw new Error("This browser doesn't support the clipboard API");
  }

  const blob = new Blob([args.value], { type: args.type });
  const data = [new ClipboardItem({ [args.type]: blob })];

  return navigator.clipboard.write(data);
}

export function isElementFocused(el: HTMLElement) {
  return document.activeElement === el;
}

export function isFocusWithinElement(el: HTMLElement) {
  return el.contains(document.activeElement);
}

/**
 * Loads a javascript script from the provided source and
 * appends it to the document body in a `script` el.
 *
 * @returns a promise which resolves when the script has
 *   finished loading.
 */
export function loadScript(src: string): Promise<unknown> {
  const scriptEl = document.createElement("script");
  scriptEl.type = "text/javascript";
  scriptEl.src = src;
  scriptEl.async = true;
  scriptEl.defer = true;
  const deferred = new DeferredPromise<Event>();
  scriptEl.onload = deferred.resolve;
  scriptEl.onerror = deferred.reject;
  document.body.appendChild(scriptEl);
  return deferred.promise;
}

function getElementPositionInContainer(args: {
  containerPos: Pick<DOMRect, "top" | "bottom">;
  elementPos: number;
  /**
   * Offsets the container position by the provided amount for
   * the top and/or bottom of the container.
   *
   * Useful if the container has a floating header/footer elements which
   * might hide content.
   */
  containerPosOffset?: {
    top?: number;
    bottom?: number;
  };
}) {
  const { elementPos, containerPos } = args;
  const topOffset = args.containerPosOffset?.top || 0;
  const bottomOffset = args.containerPosOffset?.bottom || 0;

  if (elementPos < containerPos.top + topOffset) {
    return "above" as const;
  } else if (elementPos > containerPos.bottom - bottomOffset) {
    return "below" as const;
  } else {
    return "visible" as const;
  }
}

/**
 * Get the position of an relevant it's container.
 */
export function elementPositionInContainer(args: {
  container: HTMLElement;
  element: HTMLElement;
  /**
   * Offsets the container position by the provided amount for
   * the top and/or bottom of the container.
   *
   * Useful if the container has a floating header/footer elements which
   * might hide content.
   */
  containerPosOffset?: {
    top?: number;
    bottom?: number;
  };
}) {
  const { container, element, containerPosOffset } = args;

  const elementPos = element.getBoundingClientRect();

  if (container === document.body) {
    const containerPos = {
      top: 0,
      // source https://stackoverflow.com/a/8876069/5490505
      bottom: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
    };

    return {
      top: getElementPositionInContainer({
        containerPos,
        elementPos: elementPos.top,
        containerPosOffset,
      }),
      bottom: getElementPositionInContainer({
        containerPos,
        elementPos: elementPos.bottom,
        containerPosOffset,
      }),
    };
  }

  const containerPos = container.getBoundingClientRect();

  return {
    top: getElementPositionInContainer({
      containerPos,
      elementPos: elementPos.top,
      containerPosOffset,
    }),
    bottom: getElementPositionInContainer({
      containerPos,
      elementPos: elementPos.bottom,
      containerPosOffset,
    }),
  };
}

export function scrollContainerToBottomOfElement(args: {
  container: HTMLElement;
  element: HTMLElement;
  offset?: number;
}) {
  const { container, element, offset = 0 } = args;
  setScrollTop(container, element.offsetTop + element.offsetHeight - offset);
}

export function scrollContainerToTopOfElement(args: { container: HTMLElement; element: HTMLElement; offset?: number }) {
  const { container, element, offset = 0 } = args;
  setScrollTop(container, element.offsetTop + offset);
}

export const WINDOW_RESIZE_EVENT$ = fromEvent<UIEvent>(window, "resize").pipe(
  throttleTime(250, undefined, { leading: false, trailing: true }),
  share({ resetOnRefCountZero: true }),
);

export const WINDOW_SIZE$ = WINDOW_RESIZE_EVENT$.pipe(
  startWith(() => null),
  map(() => ({
    width: document.body.offsetWidth,
    height: document.body.offsetHeight,
  })),
);

/**
 * Gets the currently selected DOM elements and returns a copy
 * of them inside a new DIV element. `null` is returned if
 * nothing is currently selected
 *
 * @returns HTMLDIVElement
 */
export function getSelectionAsElement() {
  const selection = window.getSelection();

  if (!selection?.rangeCount) return null;

  const container = document.createElement("div");

  for (let i = 0; i < selection.rangeCount; ++i) {
    container.appendChild(selection.getRangeAt(i).cloneContents());
  }

  return container;
}

/**
 * This function receives the width and height values of an image element and returns
 * new width and height values that clamp the maxHeight and/or the width to the maxWidth
 * while preserving the aspect ratio.
 *
 * Note that, so far as I can tell, we cannot accomplish this responsive image sizing using
 * css. The problem with css is that when you add css width, height, max-width, or max-height
 * css styles to an image, the browser *ignores* the width/height attributes. The effect
 * is that browser will no longer "box out" the space for the image leading to layout
 * shift when the image loads.
 */
export function calculateImageDimensions(props: {
  width: number;
  height: number;
  maxHeight?: number;
  maxWidth?: number;
}): { width: number; height: number } {
  const { maxHeight, maxWidth } = props;
  let { width, height } = props;

  const aspectRatio = width / height;

  // If maxWidth is provided and the width exceeds it, adjust both width and height
  if (maxWidth !== undefined && width > maxWidth) {
    width = maxWidth;
    height = width / aspectRatio;
  }

  // If maxHeight is provided and the height exceeds it, adjust both height and width
  if (maxHeight !== undefined && height > maxHeight) {
    height = maxHeight;
    width = height * aspectRatio;
  }

  return { width, height };
}

/**
 * Returns an RXJS observable that immediately emits with the scrollHeight of
 * the container and re-emits when the scrollHeight of the container has
 * changed.
 *
 * @param container the scrollable container
 */
export function observeScrollHeight(container: Element) {
  // Note that there is no API for directly observing scrollHeight changes
  // of an element. The ResizeObserver API can be used to observe changes
  // in the size of an element as rendered on screen by the browser (which
  // is different and smaller than the scrollHeight for scrollable elements).
  // The MutationObserver API can be used to observe changes in the DOM for
  // an element, but it does not provide a way to observe changes that don't
  // affect the DOM like an image being loaded.
  //
  // So we know that our container's scrollHeight *might* have changed if the
  // container itself emits a ResizeEvent or if a direct child of the container
  // emits a ResizeEvent. So
  // 1. We observe resize changes on the container itself.
  // 2. We observe additions/removals of direct children of our container and
  //    listen for ResizeObserver events on them.

  return new Observable<number>((subscriber) => {
    const resizeObserver = new ResizeObserver(() => {
      // Emit the scrollHeight whenever a resize event is detected
      subscriber.next(container.scrollHeight);
    });

    const mutationObserver = new MutationObserver((mutations) => {
      // A child has been added or removed from the container so re-emit the
      // scrollHeight.
      subscriber.next(container.scrollHeight);

      for (const mutation of mutations) {
        // Observe the new children for size changes
        for (const node of mutation.addedNodes) {
          if (!(node instanceof Element)) continue;
          resizeObserver.observe(node);
        }

        // Stop observing removed children
        for (const node of mutation.removedNodes) {
          if (!(node instanceof Element)) continue;
          resizeObserver.unobserve(node);
        }
      }
    });

    // Observe resize events for the container
    resizeObserver.observe(container);

    // Observe direct children for size changes
    for (const child of container.children) {
      resizeObserver.observe(child);
    }

    // Start observing the container for child changes
    mutationObserver.observe(container, { childList: true });

    // Return cleanup logic for when the observable is unsubscribed
    return () => {
      mutationObserver.disconnect();
      resizeObserver.disconnect();
    };
  }).pipe(
    startWith(() => container.scrollHeight),
    distinctUntilChanged(),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );
}

export function observeScrollEvents(container: Element) {
  const element = container === document.body ? window : container;
  return fromEvent<UIEvent>(element, "scroll", { passive: true }).pipe(share({ resetOnRefCountZero: true }));
}

/** Returns `true` if the browser supports push notifications */
export const checkNotificationSupport = () => {
  if (!("serviceWorker" in navigator)) {
    return false;
  }

  if (!("Notification" in window)) {
    return false;
  }

  if (!("PushManager" in window)) {
    return false;
  }

  return true;
};

/**
 * Generates a string ID for the html `id` property of a timeline entry list item.
 */
export function getTimelineEntryElementId(props: { thread_id: string; entry_id: string }) {
  const timelineId = generateRecordId("thread_timeline", props);
  return `TimelineEntry-${timelineId}`;
}

/** Returns true if Comms is running as an installed PWA */
export function isInstalledApp(): boolean {
  return (
    window.matchMedia("(display-mode: standalone)").matches ||
    (window.navigator as any).standalone === true ||
    isNativeIOS()
  );
}

export function isSharedWorkerSupported() {
  // For the e2e tests we support mocking this value;
  return (globalThis as any).fakeIsSharedWorkerSupported ?? typeof SharedWorker !== "undefined";
}

let parser: UAParser | undefined;

export function getUAParser() {
  if (!parser) {
    parser = new UAParser();
  }

  return parser;
}

// So far as I'm aware, there isn't a browser API we can reliably test for to determine if
// installation is supported. Instead we try to detect the browser engine and use that as
// a basis for determining installation support.
export function isPWASupported() {
  if (pwaSupprted === undefined) {
    const engine = getUAParser().getEngine();
    pwaSupprted = engine.name === "Blink" || engine.name === "WebKit";
  }

  return pwaSupprted;
}

let pwaSupprted: boolean | undefined;

export function isDesktopBrowser() {
  if (isDesktop === undefined) {
    // Device type will be `undefined` for desktop browsers
    isDesktop = !getUAParser().getDevice().type;
  }

  return isDesktop;
}

let isDesktop: boolean | undefined;

export function isPersistedDbSupported() {
  // For the e2e tests we support mocking this value;
  return (globalThis as any).fakeIsPersistedDbSupported ?? (isDesktopBrowser() || isInstalledApp());
}

export function isPWA() {
  return window.matchMedia("(display-mode: standalone)").matches;
}
