import { ComponentType } from "react";
import { stripIndents } from "common-tags";
import { UnreachableCaseError } from "libs/errors";
import { uuid } from "libs/uuid";
import { create } from "zustand";

export type IToastOptions = Partial<Omit<TToast, "timeoutId" | "type">>;

export type ToastStore = {
  toasts: TToast[];
  addToast: (toast: TToast) => void;
  removeToast: (toastId: string) => void;
};

export interface TToast {
  id: string;
  type: string;
  timeoutId: number;
  subject?: string;
  description?: string;
  durationMs: number;
  toastCSS?: string;
  dontDismissOnHover?: boolean;
  Action?: ComponentType<{}>;
  onAction?: () => void;
  onDismiss?: () => void;
}

export const useToastStore = create<ToastStore>((set) => ({
  toasts: [] as TToast[],
  addToast: (toast) => {
    set((state) => ({ toasts: [...state.toasts, toast] }));
  },
  removeToast: (toastId) => {
    set((state) => {
      const toast = state.toasts.find((t) => t.id === toastId);

      // `clearTimeout()` accepts `null` and `undefined` as a value, it's just a noop
      clearTimeout(toast?.timeoutId as any);

      return { toasts: state.toasts.filter((t) => t.id !== toastId) };
    });
  },
}));

const addToast = (toast: TToast) => useToastStore.getState().addToast(toast);
const removeToast = (toastId: string) => useToastStore.getState().removeToast(toastId);

interface IToastMap {
  vanilla: IToastOptions;
}

/**
 * Creates a toast notification
 * @returns Function to remove the toast
 */
export function toast<T extends keyof IToastMap>(type: T, options: IToastMap[T]) {
  let toastId: string;

  switch (type) {
    case "vanilla": {
      toastId = createVanillaToast(type, options);
      break;
    }
    default: {
      throw new UnreachableCaseError(type as never);
    }
  }

  return () => removeToast(toastId);
}

// function createUndoToast(
//   type: "undo",
//   options: SetRequired<IToastOptions, "onAction">,
// ) {
//   const undoTimeout =
//     !options.durationMs || options.durationMs > 10_000
//       ? 10_000
//       : options.durationMs;

//   const action = options.onAction;

//   const toastId = createVanillaToast(type, {
//     subject: options.subject,
//     durationMs: undoTimeout,
//     Action: () => (
//       <ToastAction name="Undo">
//         <kbd className="mx-1">Z</kbd>
//       </ToastAction>
//     ),
//     onAction: () => {
//       undoCallback?.();
//       clearUndo();
//     },
//     onDismiss: () => {
//       clearUndo();
//     },
//   });

//   registerUndo(
//     () => {
//       removeToast(toastId);
//       action();
//     },
//     {
//       timeout: undoTimeout,
//     },
//   );

//   return toastId;
// }

function createVanillaToast(type: string, options: IToastOptions = {}) {
  const toastId = options.id || uuid();

  const durationMs =
    !options.durationMs ? 3000
    : options.durationMs === Infinity ? Number.MAX_SAFE_INTEGER
    : options.durationMs;

  const timeoutId = setTimeout(() => removeToast(toastId), durationMs) as unknown as number;

  const state = {
    ...options,
    timeoutId,
    durationMs,
    id: toastId,
    type,
  };

  if (options.onAction) {
    const actionFn = options.onAction;

    state.onAction = () => {
      removeToast(toastId);
      actionFn();
    };
  }

  addToast(state);

  return toastId;
}

export function showNotImplementedToastMsg(
  description = stripIndents`
    Unfortunately, this feature isn't currently supported. 
    Annoying, I know. I want this feature too...
  `,
) {
  toast("vanilla", {
    subject: "Not yet implemented 😭",
    description,
    durationMs: 7000,
  });
}
