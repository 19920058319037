import { ComponentType, useRef } from "react";
import { IListRef, ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { toast } from "~/environment/toast-service";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import { RemindMeDialogState } from "~/dialogs/remind-me";
import {
  ESCAPE_TO_INBOX_COMMAND,
  markDoneCommand,
  markNotDoneCommand,
  setThreadReminderCommand,
  removeThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
} from "~/utils/common-commands";
import * as MainLayout from "~/page-layouts/main-layout";
import { ContentList, EmptyListMessage, useKBarAwareFocusedEntry$ } from "~/components/content-list/ContentList";
import { useDoneNotifications } from "~/hooks/useDoneNotifications";
import { PointerWithRecord } from "libs/schema";
import { triageThread } from "~/actions/notification";
import { NotificationEntry, onNotificationSelectNavigateToThread } from "~/components/content-list/NotificationEntry";
import { useListPaging } from "~/hooks/useListPaging";
import { EndOfListMsg } from "~/components/EndOfListMsg";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import {
  MarkAllNotDoneEntryAction,
  OtherCommandEntryAction,
  SetReminderForAllEntryAction,
} from "~/components/content-list/layout";
import { useRegisterBulkRecordActionCommands } from "~/hooks/useRegisterBulkEntryActions";
import { useIsDesktopBrowser } from "~/hooks/useIsDesktopBrowser";
import useConstant from "use-constant";

export const DoneView: ComponentType<{}> = () => {
  const scrollboxRef = useRef<HTMLElement>(document.body);
  const headerRef = useRef<HTMLElement>(null);

  const [notificationIds, { fetchMore, isLoading, nextId }] = useDoneNotifications();

  const noMoreNotifications = !nextId && !isLoading;

  useListPaging({
    fetchMore,
    isLoading,
    isListEnd: noMoreNotifications,
    pagingScrollboxRef: scrollboxRef,
  });

  const listRef = useRef<IListRef<PointerWithRecord<"notification">>>(null);

  useRegisterBulkRecordActionCommands({
    priority: { delta: 1 },
    listRef,
    isListRefSet: !!notificationIds.length,
  });

  const { setFocusedEntry, useFocusedEntry } = useKBarAwareFocusedEntry$<PointerWithRecord<"notification">>();

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  return (
    <>
      <RegisterDoneNotificationEntryCommands useFocusedNotification={useFocusedEntry} />

      <Helmet>
        <title>Done | Comms</title>
      </Helmet>

      <MainLayout.Header
        ref={headerRef}
        noStickyWhenEntriesSelected={{
          listRef,
          isListRefSet: notificationIds.length > 0,
        }}
      >
        <h1 className="text-3xl">Done</h1>
      </MainLayout.Header>

      <MainLayout.ActionsBar
        listRef={listRef}
        isListRefSet={notificationIds.length > 0}
        multiSelectActions={
          <>
            <MarkAllNotDoneEntryAction />
            <SetReminderForAllEntryAction />
            <OtherCommandEntryAction />
          </>
        }
      />

      <ListScrollbox isBodyElement offsetHeaderEl={headerRef} onlyOffsetHeaderElIfSticky>
        {notificationIds.length === 0 ?
          <EmptyListMessage text="Nothing yet." />
        : <ContentList
            ref={listRef}
            onEntryFocused={setFocusedEntry}
            onEntryAction={onNotificationSelectNavigateToThread}
            className="mb-20"
            autoFocus
          >
            {notificationIds.map((notificationId, index) => (
              <NotificationEntry key={notificationId} notificationId={notificationId} relativeOrder={index} />
            ))}

            <EndOfListMsg isEnd={noMoreNotifications} />
          </ContentList>
        }
      </ListScrollbox>
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const RegisterDoneNotificationEntryCommands: ComponentType<{
  useFocusedNotification: () => PointerWithRecord<"notification"> | null;
}> = (props) => {
  const focusedNotification = props.useFocusedNotification();
  const environment = useClientEnvironment();

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [ESCAPE_TO_INBOX_COMMAND];

      if (focusedNotification) {
        commands.push(
          markDoneCommand({
            callback: () => {
              toast("vanilla", {
                subject: "Post already marked done.",
                description: "Hint: use Shift+E to mark as not done.",
              });
            },
          }),
          markNotDoneCommand({
            callback: () => {
              triageThread(environment, {
                threadId: focusedNotification.record.thread_id,
                done: false,
              });
            },
          }),
          setThreadReminderCommand({
            callback: () => {
              RemindMeDialogState.open({
                threadId: focusedNotification.record.thread_id,
                fetchStrategy: environment.recordLoader.options.defaultFetchStrategy,
              });
            },
          }),
        );

        if (focusedNotification.record.has_reminder) {
          commands.push(
            removeThreadReminderCommand({
              callback: () => {
                triageThread(environment, {
                  threadId: focusedNotification.record.thread_id,
                  triagedUntil: null,
                });
              },
            }),
          );
        }

        if (focusedNotification.record.is_starred) {
          commands.push(
            unstarThreadCommand({
              callback: () => {
                triageThread(environment, {
                  threadId: focusedNotification.record.thread_id,
                  isStarred: false,
                });
              },
            }),
          );
        } else {
          commands.push(
            starThreadCommand({
              callback: () => {
                triageThread(environment, {
                  threadId: focusedNotification.record.thread_id,
                  isStarred: true,
                });
              },
            }),
          );
        }
      }

      return commands;
    },
    deps: [focusedNotification, environment],
  });

  return null;
};

/* -----------------------------------------------------------------------------------------------*/
