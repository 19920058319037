import { Editor, EditorContent, useEditor, EditorOptions, FocusPosition, BubbleMenu } from "@tiptap/react";
import { Editor as CoreEditor, Extensions } from "@tiptap/core";
import { ComponentType, forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from "react";
import Typography from "@tiptap/extension-typography";
import { css, cx } from "@emotion/css";
import { red, slateDark } from "@radix-ui/colors";
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import { lowlight } from "lowlight";
import Link from "@tiptap/extension-link";
import { ListItem } from "./extensions/ListItem";
import { useAsRef } from "~/hooks/useAsRef";
import { ImageExtension } from "./extensions/image";
import { buildEditorOverflowHandler } from "./extensions/EditorOverflowHandler";
import { CommsShortcuts } from "./extensions/CommsShortcuts";
import { CustomizedStarterKit } from "./extensions/CustomizedStarterKit";
import { PLATFORM_MODIFIER_KEY } from "~/environment/command.service";
import Emoji from "./extensions/emoji";
import { buildMentionExtension, useMentionExtensionSubscriptions } from "./extensions/mention";
import { DropHandler } from "./extensions/drop-handler/extension";
import { PasteHandler } from "./extensions/paste-handler/extension";
import { insertHtmlToEditorAndUploadImages, uploadAndInsertImagesToEditor } from "./uploads";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useAssertInvariant } from "~/hooks/useAssertInvariant";
import * as RadixToolbar from "@radix-ui/react-toolbar";
import {
  StrikethroughIcon,
  FontBoldIcon,
  FontItalicIcon,
  CodeIcon,
  Link2Icon,
  LinkBreak2Icon,
} from "@radix-ui/react-icons";
import { Tooltip } from "~/components/Tooltip";
import {
  MdFormatListNumbered,
  MdFormatListBulleted,
  MdFormatQuote,
  MdOutlineTextIncrease,
  MdOutlineTextDecrease,
} from "react-icons/md";
import { toast } from "~/environment/toast-service";
import { EditHyperlinkDialog, EditHyperlinkDialogState } from "~/dialogs/edit-hyperlink/EditHyperlinkDialog";
import { firstValueFrom } from "rxjs";

export interface IMessageEditorRef {
  editor: Editor | null;
  /**
   * An array of callback functions which are run after the
   * TipTap editor is created. Any code with access to this
   * property can add new callbacks to this array to have
   * them called after the TipTap editor is created.
   */
  onCreate: Array<(editor: CoreEditor) => void>;
  /**
   * Adds a callback function to the `onCreate` array. If the editor
   * is already created, the callback is run immediately.
   */
  addOnCreate(fn: (editor: CoreEditor) => void): void;
  focus(position: FocusPosition, options?: { scrollIntoView?: boolean }): void;
}

export const MessageEditorBase = forwardRef<
  IMessageEditorRef,
  {
    messageId: string;
    saveDraftFn: () => Promise<void>;
    className?: string;
    onChange?: (props: { editor: CoreEditor }) => void;
    onBlur?: () => void;
    onEditorStartOverflow?: () => void;
    onEditorEndOverflow?: () => void;
    getInitialValue?: () => string;
    initialTabIndex?: number;
  }
>((props, ref) => {
  useMentionExtensionSubscriptions();

  const messageId = props.messageId;
  const environment = useClientEnvironment();
  const environmentRef = useAsRef(environment);
  const onChangeRef = useAsRef(props.onChange);
  const onEditorStartOverflowRef = useAsRef(props.onEditorStartOverflow);
  const onEditorEndOverflowRef = useAsRef(props.onEditorEndOverflow);
  const onEditorCreateRef = useRef<Array<(editor: CoreEditor) => void>>([]);
  /** Is null before the editor is created and is a CoreEditor instance after */
  const isEditorCreatedRef = useRef<CoreEditor | null>(null);
  const saveDraftFnRef = useAsRef(props.saveDraftFn);

  const { MentionLevel1, MentionLevel2, MentionLevel3 } = useMemo(() => {
    const MentionLevel1 = buildMentionExtension({ environmentRef, char: "@" });
    const MentionLevel2 = buildMentionExtension({ environmentRef, char: "@@" });
    const MentionLevel3 = buildMentionExtension({ environmentRef, char: "@@@" });

    return {
      MentionLevel1,
      MentionLevel2,
      MentionLevel3,
    };
  }, []);

  const config = useMemo<Partial<EditorOptions>>(() => {
    // Note, the order of extensions can matter. E.g.
    // the order of these extensions dictates the order of precedence
    // for hotkey commands.
    const extensions: Extensions = [
      CustomizedStarterKit,
      Typography,
      ImageExtension,
      CommsShortcuts,
      CodeBlockLowlight.configure({
        lowlight,
      }),
      // ListItem needs to come before the @mention/etc extensions
      // so that the `@mention` extensions process Enter keydown events.
      // addresses https://github.com/levelshealth/comms/issues/482
      ListItem,
      MentionLevel1,
      MentionLevel2,
      MentionLevel3,
      Emoji,
      Link.configure({
        HTMLAttributes: {
          target: null,
        },
        openOnClick: false,
      }),
      buildEditorOverflowHandler(onEditorStartOverflowRef, onEditorEndOverflowRef),
      DropHandler.configure({
        onDrop({ editor, event }) {
          const { dataTransfer } = event;

          if (!dataTransfer) return false;

          const coords = editor.view.posAtCoords({
            left: event.clientX,
            top: event.clientY,
          });

          const position = coords?.pos || 0;

          return onEditorDataTransfer(environmentRef.current, {
            messageId,
            editor,
            dataTransfer,
            position,
            saveDraftFn: saveDraftFnRef.current,
          });
        },
      }),
      PasteHandler.configure({
        onPaste({ editor, event }) {
          const { clipboardData } = event;

          if (!clipboardData) return false;

          return onEditorDataTransfer(environmentRef.current, {
            messageId,
            editor,
            dataTransfer: clipboardData,
            position: editor.view.state.selection.head,
            saveDraftFn: saveDraftFnRef.current,
          });
        },
      }),
    ];

    const editorAttributes: { [name: string]: string } = {};

    if (Number.isInteger(props.initialTabIndex)) {
      editorAttributes.tabindex = String(props.initialTabIndex);
    }

    return {
      extensions,
      content: props.getInitialValue?.() || "",
      onCreate({ editor }) {
        isEditorCreatedRef.current = editor;
        onEditorCreateRef.current.forEach((fn) => fn(editor));
        onEditorCreateRef.current.length = 0;
      },
      onUpdate({ editor }) {
        onChangeRef.current?.({ editor });
      },
      editorProps: {
        attributes: editorAttributes,
      },
    };
  }, [messageId, MentionLevel1, MentionLevel2, MentionLevel3]);

  // While we could theoretically support a changing config, we currently don't.
  // It's also not clear why we would ever need the config to change.
  useAssertInvariant(config, "TipTap config cannot change after initialization");

  const editor = useEditor(config, [config]);

  useImperativeHandle(
    ref,
    () => ({
      editor,
      onCreate: onEditorCreateRef.current,
      /**
       * Adds a callback function to the `onCreate` array. If the editor
       * is already created, the callback is run immediately.
       */
      addOnCreate(fn: (editor: CoreEditor) => void) {
        onEditorCreateRef.current.push(fn);

        if (isEditorCreatedRef.current) {
          fn(isEditorCreatedRef.current);
        }
      },
      /**
       * If the editor is initialized, will immediately focus it. Else,
       * will register onCreate callback to focus the editor after creation.
       */
      focus(position: FocusPosition, options: { scrollIntoView?: boolean } = {}) {
        if (this.editor) {
          this.editor.commands.focus(position, options);
        } else {
          // In Safari especially, sometimes the editor is not initialized
          // syncronously or even on the next tick
          this.onCreate.push((editor) => {
            editor.commands.focus(position, options);
          });
        }
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor],
  );

  // After initialization, emit one change to sync the editors values
  // with the control.
  useEffect(() => {
    if (!editor) return;
    onChangeRef.current?.({ editor: editor });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  if (!editor) return editor;

  return (
    <>
      <BubbleMenu editor={editor} tippyOptions={{ zIndex: 50 }}>
        <Toolbar>
          <ToolbarButton
            isActive={editor.isActive("bold")}
            onClick={() => editor.chain().focus().toggleBold().run()}
            aria-label="Bold"
          >
            <FontBoldIcon className="scale-125" />
          </ToolbarButton>

          <ToolbarButton
            isActive={editor.isActive("italic")}
            onClick={() => editor.chain().focus().toggleItalic().run()}
            aria-label="Italic"
          >
            <FontItalicIcon className="scale-125" />
          </ToolbarButton>

          <ToolbarButton
            isActive={editor.isActive("strike")}
            onClick={() => editor.chain().focus().toggleStrike().run()}
            aria-label="Strikethrough"
          >
            <StrikethroughIcon className="scale-125" />
          </ToolbarButton>

          <ToolbarButton
            isActive={editor.isActive("code")}
            onClick={() => editor.chain().focus().toggleCode().run()}
            aria-label="Mark as code"
          >
            <CodeIcon className="scale-125" />
          </ToolbarButton>

          <ToolbarButton
            isActive={editor.isActive("link")}
            onClick={async () => {
              let href: string | undefined;

              if (editor.isActive("link")) {
                const linkAttributes = editor.getAttributes("link");
                href = linkAttributes.href;
              }

              EditHyperlinkDialogState.open({ url: href });

              const result = await firstValueFrom(EditHyperlinkDialogState.afterClose$);

              if (!result) {
                // The form was cancelled so do nothing
              } else if (!result.url) {
                editor.chain().focus().unsetLink().run();
              } else {
                editor.chain().focus().setLink({ href: result.url }).run();
              }
            }}
            aria-label={editor.isActive("link") ? "Edit link" : "Add link"}
          >
            <EditHyperlinkDialog />
            <Link2Icon className="scale-125" />
          </ToolbarButton>

          {editor.isActive("link") && (
            <ToolbarButton onClick={() => editor.chain().focus().unsetLink().run()} aria-label="Remove link">
              <LinkBreak2Icon className="scale-125" />
            </ToolbarButton>
          )}

          <ToolbarSeparator />

          <ToolbarButton
            isActive={editor.isActive("bulletList")}
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            aria-label="Format as bullet list"
          >
            <MdFormatListBulleted className="scale-125" />
          </ToolbarButton>

          <ToolbarButton
            isActive={editor.isActive("orderedList")}
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            aria-label="Format as number list"
          >
            <MdFormatListNumbered className="scale-125" />
          </ToolbarButton>

          <ToolbarButton
            isActive={editor.isActive("blockquote")}
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            aria-label="Format as blockquote"
          >
            <MdFormatQuote className="scale-125" />
          </ToolbarButton>

          <ToolbarSeparator />

          <ToolbarButton
            onClick={() => {
              if (editor.isActive("heading", { level: 1 })) {
                toast("vanilla", {
                  subject: "Text cannot get larger",
                });
              } else if (editor.isActive("heading", { level: 2 })) {
                editor.chain().focus().setHeading({ level: 1 }).run();
              } else if (editor.isActive("heading", { level: 3 })) {
                editor.chain().focus().setHeading({ level: 2 }).run();
              } else {
                editor.chain().focus().setHeading({ level: 3 }).run();
              }
            }}
            aria-label="Increase text size"
          >
            <MdOutlineTextIncrease className="scale-125" />
          </ToolbarButton>

          <ToolbarButton
            onClick={() => {
              if (editor.isActive("heading", { level: 1 })) {
                editor.chain().focus().setHeading({ level: 2 }).run();
              } else if (editor.isActive("heading", { level: 2 })) {
                editor.chain().focus().setHeading({ level: 3 }).run();
              } else if (editor.isActive("heading", { level: 3 })) {
                editor.chain().focus().setParagraph().run();
              } else {
                toast("vanilla", {
                  subject: "Text cannot get smaller",
                });
              }
            }}
            aria-label="Decrease text size"
          >
            <MdOutlineTextDecrease className="scale-125" />
          </ToolbarButton>
        </Toolbar>
      </BubbleMenu>

      <EditorContent
        onBlur={props.onBlur}
        className={cx("RichTextEditor", editorStyles, props.className)}
        editor={editor}
        onKeyDown={(e) => {
          if ((PLATFORM_MODIFIER_KEY.name === "Command" ? e.metaKey : e.ctrlKey) && (e.key === "[" || e.key === "]")) {
            e.preventDefault();
          }
        }}
      />
    </>
  );
});

/* -----------------------------------------------------------------------------------------------*/

const editorStyles = css`
  width: 100%;

  .ProseMirror-focused {
    outline: none;
  }

  .ProseMirror p.is-editor-empty:first-child::before {
    color: ${slateDark.slate11};
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  &.is-invalid .ProseMirror p:first-child::before {
    color: ${red.red9};
  }
`;

/* -----------------------------------------------------------------------------------------------*/

function onEditorDataTransfer(
  environment: Pick<ClientEnvironment, "api" | "logger">,
  props: {
    editor: CoreEditor;
    dataTransfer: DataTransfer;
    position: number;
    messageId: string;
    saveDraftFn: () => Promise<void>;
  },
): boolean {
  const { editor, dataTransfer, position, messageId, saveDraftFn } = props;

  const files = dataTransfer.files;

  if (files.length) {
    return uploadAndInsertImagesToEditor(environment, {
      messageId,
      editor,
      files,
      position,
      saveDraftFn,
    });
  }

  const html = dataTransfer.getData("text/html");

  if (html) {
    return insertHtmlToEditorAndUploadImages(environment, {
      messageId,
      editor,
      html,
      position,
      saveDraftFn,
    });
  }

  return false;
}

/* -----------------------------------------------------------------------------------------------*/

const Toolbar: ComponentType = (props) => {
  return (
    <RadixToolbar.Root
      className={cx(
        `flex w-max max-w-[80vw] overflow-x-auto `,
        `border border-solid border-slate-9 rounded p-1 bg-slate-12 shadow-lg`,
      )}
      aria-label="Formatting options"
    >
      {props.children}
    </RadixToolbar.Root>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const ToolbarButton: ComponentType<{
  isActive?: boolean;
  onClick: () => void;
  "aria-label": string;
}> = (props) => {
  return (
    <Tooltip side="bottom" content={props["aria-label"]}>
      <RadixToolbar.Button
        onClick={(e) => {
          e.preventDefault();
          props.onClick();
        }}
        className={cx(
          `h-8 px-2 rounded inline-flex text-md leading-none items-center justify-center`,
          ` hover:bg-slateDark-8 hover:text-slateDark-12`,
          props.isActive ? "text-white" : "text-slateDark-11",
        )}
        aria-label={props["aria-label"]}
      >
        {props.children}
      </RadixToolbar.Button>
    </Tooltip>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const ToolbarSeparator: ComponentType = (props) => {
  return <RadixToolbar.Separator className="w-[1px] my-2 mx-3 bg-slateDark-8" />;
};

/* -----------------------------------------------------------------------------------------------*/
