import { IEditorMention, IMessageEditorControl, IRichTextEditorRef } from "~/components/forms/message-editor";
import { IEmailRecipientOption, IRecipientOption } from "~/components/forms/ThreadRecipients";
import { ContainerControls, createFormControl, createFormGroup } from "solid-forms-react";
import { RefObject, useEffect } from "react";
import { isWindowFocused, WINDOW_FOCUSED$ } from "~/environment/focus.service";
import { combineLatest, filter, map, NEVER, Observable, pairwise, skip, Subject, switchMap } from "rxjs";
import { observable } from "~/components/forms/utils";
import { PendingUpdates } from "~/environment/loading.service";
import { debounce, differenceBy } from "lodash-comms";
import { isNonNullable } from "libs/predicates";
import { UnreachableCaseError } from "libs/errors";
import { DraftAttachmentDoc, RecordValue, ThreadVisibility } from "libs/schema";
import { MentionableUser, observeMentionableUsers } from "~/observables/observeMentionableUserRecords";
import { MentionableGroup, observeMentionableGroupRecords } from "~/observables/observeMentionableGroupRecords";
import { getDraftDataStorageKey, useSyncDraftBetweenTabs } from "~/environment/draft.service";
import { deleteDraft } from "~/actions/draft";
import { getCommandFactory } from "~/utils/common-commands";
import { SetOptional } from "type-fest";
import { ICommandArgs } from "~/environment/command.service";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { getSessionStorage } from "~/environment/KVStore";

export interface IComposeMessageFormValue {
  messageId: string;
  threadId: string;
  type: RecordValue<"draft">["type"];
  isEdit: boolean;
  isReply: boolean;
  branchedFrom: null | {
    threadId: string;
    messageId: string;
    messageTimelineOrder: string;
  };
  recipients: {
    to: IRecipientOption[];
    cc: IRecipientOption[];
    bcc: IEmailRecipientOption[];
  };
  visibility: ThreadVisibility | null;
  subject: RecordValue<"draft">["new_thread_subject"];
  attachments: DraftAttachmentDoc[];
  body: {
    content: string;
    userMentions: IEditorMention[];
    groupMentions: IEditorMention[];
  };
}

export const sendMessageCommand = getCommandFactory(
  "SEND_MESSAGE",
  (options: SetOptional<ICommandArgs, "label" | "hotkeys" | "triggerHotkeysWhenInputFocused">) => ({
    label: "Send message",
    hotkeys: [
      "$mod+Enter",
      // $mod+Shift+Enter is the hotkey for "send and mark done". While
      // "marking done" doesn't apply to a new message, someone might
      // want to use the same hotkey to send a new draft because of
      // muscle memory
      "$mod+Shift+Enter",
    ],
    triggerHotkeysWhenInputFocused: true,
    ...options,
  }),
);

export const closeDraftCommand = getCommandFactory(
  "CLOSE_DRAFT",
  (options: SetOptional<ICommandArgs, "label" | "hotkeys" | "triggerHotkeysWhenInputFocused">) => ({
    label: "Close draft",
    hotkeys: ["Escape"],
    triggerHotkeysWhenInputFocused: true,
    ...options,
  }),
);

export type IComposeMessageForm = ReturnType<typeof createComposeMessageForm>;

export function createComposeMessageForm(
  initialFormValues: IComposeMessageFormValue,
  options: { recipientsRequired?: boolean } = {},
) {
  const branchedFrom =
    !initialFormValues.branchedFrom ?
      createFormControl(null)
    : createFormGroup({
        threadId: createFormControl(initialFormValues.branchedFrom.threadId),
        messageId: createFormControl(initialFormValues.branchedFrom.messageId),
        messageTimelineOrder: createFormControl(initialFormValues.branchedFrom.messageTimelineOrder),
      });

  return createFormGroup(
    {
      messageId: createFormControl<string>(initialFormValues.messageId),
      threadId: createFormControl<string>(initialFormValues.threadId),
      type: createFormControl(initialFormValues.type),
      isEdit: createFormControl(initialFormValues.isEdit),
      isReply: createFormControl(initialFormValues.isReply),
      branchedFrom,
      recipients: createFormGroup(
        {
          to: createFormControl(initialFormValues.recipients.to, {
            data: {
              focus: new Subject<void>(),
            },
          }),
          cc: createFormControl(initialFormValues.recipients.cc, {
            data: {
              focus: new Subject<void>(),
            },
          }),
          bcc: createFormControl(initialFormValues.recipients.bcc, {
            data: {
              focus: new Subject<void>(),
            },
          }),
        },
        {
          required: !!options.recipientsRequired,
        },
      ),
      visibility: createFormControl(initialFormValues.visibility, {
        required: true,
      }),
      subject: createFormControl(initialFormValues.subject || "", {
        required: true,
        data: {
          focus: new Subject<void>(),
        },
      }),
      attachments: createFormControl(initialFormValues.attachments),
      body: createFormGroup<ContainerControls<IMessageEditorControl["controls"]["body"]>>({
        content: createFormControl(initialFormValues.body.content, {
          required: true,
        }),
        userMentions: createFormControl<IEditorMention[]>(initialFormValues.body.userMentions),
        groupMentions: createFormControl<IEditorMention[]>(initialFormValues.body.groupMentions),
        // Currently in the NewThreadForm component, we only care about
        // changes to the mentionsCount. For this reason, it's fine for us
        // to initialize the control with an incorrect value. In the future
        // we might need to refactor this. See the JSDoc comment on the
        // IMessageEditorControl["controls"]["body"]["possiblyIncorrectMentionsCount"]
        // type for more info.
        possiblyIncorrectMentionsCount: createFormControl(0),
      }),
    },
    {
      validators:
        !options.recipientsRequired ? undefined : (
          (v: IComposeMessageFormValue) => {
            switch (v.type) {
              case "COMMS": {
                return v.recipients.to.length > 0 || v.recipients.cc.length > 0 ? null : { required: "recipients" };
              }
              case "EMAIL": {
                const isNonGroupRecipient = (r: IRecipientOption) => r.type === "user" || r.type === "email";

                return v.recipients.to.some(isNonGroupRecipient) || v.recipients.cc.some(isNonGroupRecipient) ?
                    null
                  : { required: "recipients" };
              }
              default: {
                throw new UnreachableCaseError(v.type);
              }
            }
          }
        ),
    },
  );
}

export function useAutosaveDraft(args: {
  control: IComposeMessageForm;
  saveDraft: (environment: ClientEnvironment, values: IComposeMessageFormValue) => void;
  cancelSaveDraft: (postId: string) => void;
  editorRef: RefObject<IRichTextEditorRef>;
}) {
  const { control, saveDraft, cancelSaveDraft, editorRef } = args;
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();

  useSyncDraftBetweenTabs(control, editorRef);

  // Periodically save drafts if the window is focused
  useEffect(() => {
    const sub = WINDOW_FOCUSED$.pipe(
      switchMap((isFocused) => (!isFocused ? NEVER : observable(() => control.rawValue).pipe(skip(1)))),
    ).subscribe((value) => {
      saveDraft(environment, value);
    });

    return () => sub.unsubscribe();
  }, [saveDraft, control, environment]);

  // When we navigate away from this draft,
  // delete the draft if it's empty
  useEffect(() => {
    // If the user has the same draft editor open in two different browsers
    // (e.g. Chrome and Safari), and the draft is blank in both browsers,
    // the other browser will not update with content as the
    // user types. This is intended, as the draft editor ignores updates
    // to the draft doc while open and, since the user is editing in a
    // different browser, there is no way to sync changes via our session
    // store service. Because of this, if the user sends the post then it
    // will cause the draft editor in the other browser to
    // close, causing this useEffect onUnmount callback to run, potentially
    // resulting in the draft being deleted and unsent. SOOooooo, here we
    // check to see if the draft or the editor has any content.
    // If it does, then we don't delete the draft

    return () => {
      if (!isWindowFocused()) return;

      const messageId = control.rawValue.messageId;
      const [draft] = environment.db.getRecord("draft", messageId);

      if (!draft) return;

      const isContentEmpty = (content?: string) => !content?.trim() || content === "<p></p>";

      const isEmptyDraft =
        isContentEmpty(draft?.body_html) &&
        isContentEmpty(control.rawValue.body.content) &&
        control.rawValue.attachments.length === 0 &&
        control.rawValue.recipients.to.length === 0 &&
        control.rawValue.recipients.cc.length === 0 &&
        control.rawValue.recipients.bcc.length === 0 &&
        !control.rawValue.subject?.trim();

      if (!isEmptyDraft) return;

      environment.logger.debug("[useAutosaveDraft] editor close: deleting draft because it's empty...");
      cancelSaveDraft(control.rawValue.messageId);
      deleteDraft(environment, {
        draftId: control.rawValue.messageId,
        currentUserId,
      });

      getSessionStorage().removeItem(getDraftDataStorageKey(control.rawValue.messageId));
    };
  }, [cancelSaveDraft, control, currentUserId, environment]);
}

export type SaveDraftFn = {
  (
    environment: ClientEnvironment,
    values: IComposeMessageFormValue,
    options: {
      immediate: true;
    },
  ): Promise<void>;
  (
    environment: ClientEnvironment,
    values: IComposeMessageFormValue,
    options?: {
      immediate?: boolean;
    },
  ): Promise<void> | undefined;
};

export function getSaveDraftFns(
  debouncedSaveFn: (
    this: { pendingDebouncePostId: string | null },
    environment: ClientEnvironment,
    values: IComposeMessageFormValue,
  ) => Promise<void>,
) {
  const context = {
    // Used to track if we currently have a pending call to our debounced
    // save function and, if so, what the postId associated with that
    // call is.
    pendingDebouncePostId: null as string | null,
  };

  const saveDraft = ((environment, values, options: { immediate?: boolean } = {}) => {
    // if the user edits one draft and then quickly moves to another,
    // we want to save the previous draft immediately before attempting
    // to save the new one
    if (context.pendingDebouncePostId && context.pendingDebouncePostId !== values.messageId) {
      saveReplyDraftDebouncedFn.flush();
    }

    context.pendingDebouncePostId = values.messageId;
    PendingUpdates.add(values.messageId);

    if (options.immediate) {
      saveReplyDraftDebouncedFn(environment, values);
      return saveReplyDraftDebouncedFn.flush()!;
    } else {
      return saveReplyDraftDebouncedFn(environment, values);
    }
  }) as SaveDraftFn;

  function cancelSaveDraft(postId: string) {
    if (context.pendingDebouncePostId !== postId) return;
    context.pendingDebouncePostId = null;
    saveReplyDraftDebouncedFn.cancel();
    PendingUpdates.remove(postId);
  }

  // We're using lodash for debounce instead of rxjs to ensure that
  // the function is called even if the component is destroyed.
  // If we were using rxjs, the observable would be unsubscribed from
  // when the component unmounted and the save would never happen.
  // (admittedly, we could work around this, but this approach seemed
  // easier)
  const saveReplyDraftDebouncedFn = debounce(debouncedSaveFn.bind(context), 1500, {
    // This value is informed by the fact that, when updating the service worker,
    // we'll wait up to 4 seconds for any pending updates to resolve before attempting
    // to force update the service worker.
    maxWait: 3000,
    trailing: true,
  });

  return [saveDraft, cancelSaveDraft] as const;
}

export function usePromptToRemoveRecipientOnMentionRemoval(control: IComposeMessageForm) {
  const { currentUserId } = useAuthGuardContext();
  const environment = useClientEnvironment();

  useEffect(() => {
    const removedMentions$ = observable(() => [
      ...control.rawValue.body.userMentions,
      ...control.rawValue.body.groupMentions,
    ]).pipe(
      pairwise(),
      // Mentions will never be mutated, only added/removed.
      // While mentions are generally added/removed one at a time, they can be
      // changed in bulk if the user deletes a paragraph.
      map(([previous, next]) => {
        const added = differenceBy(next, previous, "id");
        const removed = differenceBy(previous, next, "id");

        return added.length > 0 ?
            { type: "ADDED" as const, value: added }
          : { type: "REMOVED" as const, value: removed };
      }),
      filter((change): change is { type: "REMOVED"; value: IEditorMention[] } => change.type === "REMOVED"),
      map((change) => change.value),
    );

    const removedUsersAndGroups$: Observable<Array<MentionableUser | MentionableGroup>> = combineLatest([
      observeMentionableUsers(environment, { currentUserId }),
      observeMentionableGroupRecords(environment, { currentUserId }),
      removedMentions$,
    ]).pipe(
      map(([[users], [groups], removedMentions]) =>
        removedMentions
          .map((m) => users.find((user) => user.id === m.id) || groups.find((group) => group.id === m.id))
          .filter(isNonNullable),
      ),
    );

    const sub = removedUsersAndGroups$.subscribe((removedUsersAndGroups) => {
      for (const removed of removedUsersAndGroups) {
        const recipient = control.rawValue.recipients.to.find((r) => r.value === removed.id);

        if (!recipient) continue;
        if (recipient.dontPromptToRemoveOnMentionDeletion) continue;

        const response = confirm(
          `Do you want remove ${
            removed.type === "group" ? `"${removed.record.name}" group` : `"${removed.profile.name}"`
          } as a recipient?`,
        );

        if (response) {
          control.patchValue({
            recipients: {
              to: control.rawValue.recipients.to.filter((r) => r.value !== removed.id),
            },
          });
        }
      }
    });

    return () => sub.unsubscribe();
  }, [control, currentUserId, environment]);
}
