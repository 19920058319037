import { router } from "./Routes";
import { PortalContainerContext } from "~/environment/portal.service";
import useConstant from "use-constant";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { TooltipProvider } from "./components/Tooltip";
import { PendingRequestBarProvider } from "./components/PendingRequestBar";
import { HintViewport } from "./environment/hint-service";
import { lazy, Suspense } from "react";
import { OfflineBanner } from "./components/OfflineBanner";
import { RouterProvider } from "react-router-dom";
import { LoadingModal } from "./dialogs/LoadingModal";
import { DarkModeProvider } from "./components/DarkMode";
import { CreateAndProvideClientEnvironment } from "./environment/ClientEnvironmentContext";
import { ToastViewport } from "./environment/toast-service";
import { ProvideRootCommandContext } from "./environment/command.service";
import { globalState } from "./state/global.state";
import { GlobalErrorView } from "./page-dialogs/GlobalErrorView";
import { LoadingText } from "./components/LoadingText";
import { UpdateAvailableBanner } from "./components/UpdateAvailableBanner";

const UnsupportedBrowserWarningBanner = lazy(() =>
  import("~/environment/detect-browser.service").then((m) => ({
    default: m.UnsupportedBrowserWarningBanner,
  })),
);

export default function App() {
  const portalContainer = useConstant(() => document.body);
  const showIsLoading = globalState((s) => s.showIsLoading);
  const globalErrorMessage = globalState((s) => s.errorMessage);

  if (showIsLoading) {
    return <LoadingText />;
  }

  if (globalErrorMessage) {
    return <GlobalErrorView message={globalErrorMessage} />;
  }

  return (
    <CreateAndProvideClientEnvironment>
      <HelmetProvider>
        <ProvideRootCommandContext>
          <DarkModeProvider>
            <PortalContainerContext.Provider value={{ container: portalContainer }}>
              <TooltipProvider>
                <PendingRequestBarProvider>
                  <Helmet>
                    {/* 
                        This acts as the default page title for the app. It is expected that
                        this is overwritten by child components. It may be shown while a page
                        is loading. 
                      */}
                    <title>Comms</title>
                  </Helmet>

                  <Suspense fallback={null}>
                    <UnsupportedBrowserWarningBanner />
                  </Suspense>

                  <LoadingModal />
                  <RouterProvider router={router} />
                  <ToastViewport />
                  <HintViewport />
                  <OfflineBanner />
                  <UpdateAvailableBanner />
                </PendingRequestBarProvider>
              </TooltipProvider>
            </PortalContainerContext.Provider>
          </DarkModeProvider>
        </ProvideRootCommandContext>
      </HelmetProvider>
    </CreateAndProvideClientEnvironment>
  );
}
