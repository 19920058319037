import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { config } from "~/environment/config";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { ApiVersionError } from "libs/errors";

/**
 * Initialize Datadog RUM (Real User Monitoring) for the application.
 */
export default function initDatadog() {
  if (config.datadog.appId && config.datadog.clientToken) {
    datadogRum.init({
      applicationId: config.datadog.appId,
      clientToken: config.datadog.clientToken,
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: "datadoghq.com",
      service: "comms-client",
      env: config.mode,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: config.version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
      allowedTracingUrls: ["https://app.comms.day"],
      traceSampleRate: 50,
    });
  } else {
    console.warn("[datadog] [RUM] missing config. Skipping init.", {
      datadogConfig: config.datadog,
    });
  }

  if (config.datadog.clientToken) {
    datadogLogs.init({
      clientToken: config.datadog.clientToken,
      site: "datadoghq.com",
      service: "comms-client",
      version: config.version,
      env: config.mode,
      forwardErrorsToLogs: true,
      beforeSend: (log) => {
        // We add the environment to the global object after initialization
        const environment = (globalThis as any).environment as ClientEnvironment | undefined;

        // special handling for some network errors
        switch (log.http?.status_code) {
          case ApiVersionError.statusCode: {
            // ApiVersionErrors are expected after a breaking change so we downgrade the log level
            log.status = "info";
            break;
          }
          // 0 indicates a network error / failed to fetch error
          case 0: {
            // If we failed to perform a fetch and we are offline, we downgrade the
            // log level
            if (environment?.info.isOnline === false) {
              log.status = "info";
            }

            break;
          }
        }

        if (log.error instanceof Error) {
          const message = log.error.message.toLowerCase();

          const isApiVersionError = message.includes("network error") && message.includes("406");

          if (isApiVersionError) {
            // ApiVersionErrors are expected after a breaking change so we downgrade the log level
            log.status = "info";
          }
        }

        if (environment?.info) {
          log.logger = {
            ...log.logger,
            metadata: environment.info,
            name: (typeof log.name === "string" && log.name) || "beforeSend",
          };
        }

        return true;
      },
      sessionSampleRate: 100,
    });
  } else {
    console.warn("[datadog] [Log] missing config. Skipping init.", {
      datadogConfig: config.datadog,
    });
  }
}
