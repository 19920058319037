import { ComponentType, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { List, ListScrollbox } from "~/components/list";
import * as ThreadLayout from "~/page-layouts/thread-layout";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import { markDoneCommand } from "~/utils/common-commands";
import { ContentList } from "~/components/content-list/ContentList";
import { Tooltip } from "~/components/Tooltip";
import { BsLockFill } from "react-icons/bs";
import { expandedMessageCss } from "~/components/thread-timeline-entry/MessageEntry";
import {
  Action,
  ActionToolbarWrapper,
  actionBtnIconCSS,
  navigateToNextThreadCommand,
  navigateToPreviousThreadCommand,
} from "./ActionToolbar";
import { useNotification } from "~/hooks/useNotification";
import { MdDone } from "react-icons/md";
import { convertThreadViewPrevNextArgsToURL, useThreadViewPrevNextArgs } from "~/environment/thread-prev-next.service";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { navigateService } from "~/environment/navigate.service";
import { toast } from "~/environment/toast-service";
import { getCurrentUserSettings } from "~/queries/getCurrentUserSettings";
import { removeNotificationForPermissionDeniedThread } from "~/actions/notification";
import { navigateOnDone } from "./RegisterThreadTimelineCommands";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";

/* -------------------------------------------------------------------------------------------------
 * ThreadPermissionDenied
 * -----------------------------------------------------------------------------------------------*/

export const ThreadPermissionDenied: ComponentType<{ threadId: string }> = (props) => {
  const headerRef = useRef<HTMLElement>(null);

  return (
    <div className="MainPanel">
      <Header headerRef={headerRef} />

      <div className="flex">
        <ThreadLayout.ActionPanel>
          <ActionToolbar threadId={props.threadId} />
        </ThreadLayout.ActionPanel>

        <ThreadLayout.ContentPanel>
          <ListScrollbox isBodyElement offsetHeaderEl={headerRef} onlyOffsetHeaderElIfSticky>
            <ContentList focusOnMouseOver={false} autoFocus>
              <List.Entry id={1}>
                <div className={expandedMessageCss}>
                  <div className="PostHeader flex p-4 sm-w:px-8 sm-w:py-4 hover:cursor-pointer">
                    <div className="PostSender flex items-baseline flex-1">
                      <strong>Hi there 👋</strong>
                    </div>
                  </div>

                  <div className="PostBody p-4 sm-w:px-8 sm-w:py-4">
                    <div className="prose">
                      <p>
                        You no longer have permission to view this thread. Previously you did have permission to view
                        this thread and received a notification for it, but sometime after that your permission to
                        access the thread was removed.
                      </p>
                      <p></p>
                      <p>
                        Most often this happens because someone created a private thread and accidently added a group
                        which they didn't intend to.
                      </p>
                    </div>
                  </div>
                </div>
              </List.Entry>
            </ContentList>
          </ListScrollbox>
        </ThreadLayout.ContentPanel>

        <div className="flex-1" />
      </div>
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const Header: ComponentType<{
  headerRef: React.RefObject<HTMLElement>;
}> = (props) => {
  return (
    <>
      <Helmet>
        <title>Permission denied | Thread | Comms</title>
      </Helmet>

      <ThreadLayout.Header ref={props.headerRef} theme="dark">
        <ThreadLayout.ActionPanel />

        <ThreadLayout.ContentPanel>
          <div className="flex flex-col pt-7 pb-6 px-8 border-l-[3px] border-transparent">
            <div className="flex">
              <Tooltip side="bottom" content="This thread is private and only visible to the recipients">
                <span className="text-2xl inline-flex h-6 mr-2 hover:cursor-help mt-1">
                  <small>
                    <BsLockFill />
                  </small>
                </span>
              </Tooltip>

              <Tooltip content="You no longer have permission to view this thread" side="bottom">
                <h1 className="text-2xl line-clamp-3" style={{ lineHeight: 1.2 }}>
                  Permission denied
                </h1>
              </Tooltip>

              <div className="flex-1" />
            </div>
          </div>
        </ThreadLayout.ContentPanel>

        <div className="flex-1" />
      </ThreadLayout.Header>
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const ActionToolbar: ComponentType<{ threadId: string }> = (props) => {
  const environment = useClientEnvironment();
  const prevNextState = useThreadViewPrevNextArgs("thread", props.threadId);
  const [notification] = useNotification({ threadId: props.threadId });

  useRegisterCommands({
    commands() {
      const commands: ICommandArgs[] = [];

      if (prevNextState) {
        const prevUrl = convertThreadViewPrevNextArgsToURL(prevNextState, "previous");

        if (prevUrl) {
          commands.push(
            navigateToPreviousThreadCommand({
              callback() {
                navigateService(prevUrl, {
                  state: prevNextState.state,
                });
              },
            }),
          );
        } else {
          commands.push(
            navigateToPreviousThreadCommand({
              callback() {
                toast("vanilla", {
                  subject: "This is the first thread in the list.",
                });
              },
            }),
          );
        }

        const nextUrl = convertThreadViewPrevNextArgsToURL(prevNextState, "next");

        if (nextUrl) {
          commands.push(
            navigateToNextThreadCommand({
              callback() {
                navigateService(nextUrl, {
                  state: prevNextState.state,
                });
              },
            }),
          );
        }

        commands.push(
          markDoneCommand({
            async callback() {
              const { settings } = await getCurrentUserSettings(environment);

              await navigateOnDone({
                prevNextState,
                navBackOnThreadDone: settings?.enable_nav_back_on_thread_done,
              });

              removeNotificationForPermissionDeniedThread(environment, {
                threadId: props.threadId,
              });
            },
          }),
        );
      } else {
        commands.push(
          navigateToNextThreadCommand({
            callback() {
              toast("vanilla", {
                subject: "This is the last thread in the list.",
              });
            },
          }),
        );
      }

      return commands;
    },
    deps: [props.threadId, prevNextState, environment],
  });

  return (
    <ActionToolbarWrapper>
      {notification && !notification.is_done && (
        <Action
          label="Mark done"
          shortcut="(E)"
          onClick={(e) => {
            e.preventDefault();
            markDoneCommand.trigger();
          }}
        >
          <MdDone className={actionBtnIconCSS} />
        </Action>
      )}

      {prevNextState?.previousEntry && (
        <Action
          label="Previous"
          shortcut="(K)"
          onClick={() => {
            navigateToPreviousThreadCommand.trigger();
          }}
        >
          <FiChevronUp className={actionBtnIconCSS} />
        </Action>
      )}

      {prevNextState?.nextEntry && (
        <Action
          label="Next"
          shortcut="(J)"
          onClick={() => {
            navigateToNextThreadCommand.trigger();
          }}
        >
          <FiChevronDown className={actionBtnIconCSS} />
        </Action>
      )}
    </ActionToolbarWrapper>
  );
};

/* -----------------------------------------------------------------------------------------------*/
