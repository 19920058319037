import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { getCurrentRouterLocation } from "~/environment/navigate.service";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { focusReply } from "../utils";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { openComposeNewThreadDialog } from "~/page-dialogs/page-dialog-state";
import { unscheduleMessageToBeSent } from "~/actions/message";

export const unsendAndFocusDraft = onlyCallFnOnceWhilePreviousCallIsPending(
  withPendingRequestBar(async (props: { environment: ClientEnvironment; draftId: string; isReply: boolean }) => {
    const { environment, draftId, isReply } = props;
    const draftLocation = getCurrentRouterLocation();

    await unscheduleMessageToBeSent(environment, { messageId: props.draftId });

    if (isReply) {
      focusReply(draftId, draftLocation);
    } else {
      openComposeNewThreadDialog(draftId);
    }
  }),
);
