import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { toast } from "~/environment/toast-service";

type RemoveUserFromOrgParams = {
  organizationId: string;
  userId: string;
};

export const removeUserFromOrg = withPendingRequestBar(async function (
  environment: ClientEnvironment,
  params: RemoveUserFromOrgParams,
) {
  const confirmed = confirm(
    "Are you sure you want to delete this user from the organization? They will lose access to all organization resources.",
  );

  if (!confirmed) return;

  // Typescript seems to have better type narrowing if the response isn't destructured
  const response = await environment.api.deleteUserFromOrg(params);

  if (response.status !== 200) {
    alert(`Failed to delete user from organization.`);
    return;
  }

  toast("vanilla", { subject: "User deleted." });

  if (!response.body.recordMap) return;

  await environment.writeRecordMap(response.body.recordMap);
});

type ReactivateUserFromOrgParams = {
  organizationId: string;
  userId: string;
};

export const reactivateUserFromOrg = withPendingRequestBar(async function (
  environment: ClientEnvironment,
  params: ReactivateUserFromOrgParams,
) {
  const confirmed = confirm("Are you sure you want to reactivate this user?");

  if (!confirmed) return;

  // Typescript seems to have better type narrowing if the response isn't destructured
  const response = await environment.api.reactivateUserFromOrg(params);

  if (response.status !== 200) {
    alert(`Failed to reactivate user.`);
    return;
  }

  toast("vanilla", {
    subject: "User reactivated.",
  });

  if (!response.body.recordMap) return;

  await environment.writeRecordMap(response.body.recordMap);
});
