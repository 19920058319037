import { css, cx } from "@emotion/css";
import { ComponentType, useCallback, useMemo } from "react";
import { createKeybindingsHandler } from "libs/tinykeys";
import { List, ListScrollbox } from "~/components/list";
import { Portal } from "@radix-ui/react-portal";
import { usePortalContainerContext } from "../portal.service";
import { TToast, useToastStore } from "./toast.state";
import { useAsRef } from "~/hooks/useAsRef";

export const ToastViewport: ComponentType<{}> = () => {
  const { container } = usePortalContainerContext();

  return (
    <Portal container={container} asChild>
      <div id="toast-container" className="fixed bottom-20 sm-w:bottom-8 right-8 z-[1000]">
        <List>
          <ListScrollbox>
            <ol
              className={cx(
                "list-style-none",
                "max-w-screen m-0 outline-none",
                "min-w-[300px] max-w-[300px] md-w:max-w-[450px] leading-3",
              )}
            >
              <Toasts />
            </ol>
          </ListScrollbox>
        </List>
      </div>
    </Portal>
  );
};

const Toasts: ComponentType<{}> = () => {
  const toast = useToastStore((state) => state.toasts.at(-1));

  if (!toast) return null;

  return <VanillaToast key={toast.id} {...toast} onClose={() => useToastStore.getState().removeToast(toast.id)} />;
};

interface IToastProps extends TToast {
  onClose: () => void;
}

const toastCSS = cx(
  "flex text-white py-3 px-5 my-4 rounded",
  "items-center outline-none",
  "first:mt-0 last:mb-0",
  "bg-gradient-to-b",
  "from-slateDark-6 to-slateDark-2",
  "focus-within:from-blue-9 focus-within:to-indigo-9",
  css`
    box-shadow:
      0 10px 15px -3px rgb(0 0 0 / 50%),
      0 4px 6px -4px rgb(0 0 0 / 30%);
  `,
);

export const ToastAction: ComponentType<{ name: string }> = (props) => {
  return (
    <div className="flex items-center font-light text-slateDarkA-11 text-sm">
      {props.name}
      <div className="flex items-center border border-slateDarkA-11 ml-2 rounded">{props.children}</div>
    </div>
  );
};

const VanillaToast: ComponentType<IToastProps> = (props) => {
  const onDismiss = useAsRef(props.onDismiss);

  const onKeyDown = useMemo(() => {
    return createKeybindingsHandler({
      Escape: (event) => {
        event.preventDefault();
        event.stopPropagation();
        onDismiss.current?.();
        props.onClose();
      },
    });
    // onDismiss is a ref and doesn't change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onClose]);

  const onMouseEnter = useCallback(() => {
    if (props.dontDismissOnHover) return;
    clearTimeout(props.timeoutId);
  }, [props.dontDismissOnHover, props.timeoutId]);

  const onMouseLeave = useCallback(() => {
    if (props.dontDismissOnHover) return;
    props.timeoutId = setTimeout(
      () => useToastStore.getState().removeToast(props.id),
      props.durationMs,
    ) as unknown as number;
  }, [props.dontDismissOnHover, props.timeoutId, props.durationMs, props.id]);

  return (
    <List.Entry id={props.id} onEntryAction={props.onAction}>
      <li
        className={props.toastCSS || toastCSS}
        onKeyDown={(e) => onKeyDown(e.nativeEvent)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className="flex flex-col">
          {props.subject && (
            <div>
              <strong className="font-medium text-sm">{props.subject}</strong>
            </div>
          )}

          {props.description && (
            <div className="leading-tight">
              <small>
                <em>{props.description}</em>
              </small>
            </div>
          )}
        </div>

        {props.Action && (
          <>
            <div className="flex-1 min-w-4" />
            <props.Action />
          </>
        )}
      </li>
    </List.Entry>
  );
};
