import { css } from "@emotion/css";
import { updatableBehaviorSubject, IUpdatableBehaviorSubject } from "libs/updatableBehaviorSubject";
import { red } from "@radix-ui/colors";
import { uuid } from "libs/uuid";
import { useObservableEagerState } from "observable-hooks";
import { ComponentType, createContext, useEffect } from "react";
import { map, MonoTypeOperatorFunction, Observable } from "rxjs";
import useConstant from "use-constant";
import { createUseContextHook } from "~/utils/createUseContextHook";
import { useIsOnline } from "~/hooks/useIsOnline";

/* -------------------------------------------------------------------------------------------------
 * pendingRequestBarService
 * -------------------------------------------------------------------------------------------------
 */

export interface IPendingRequestBarState {
  loadingIds: IUpdatableBehaviorSubject<Set<string>>;
  /**
   * Adds a loading indicator. Returns a function that, when called,
   * removes the added loading indicator.
   */
  markLoading(): () => void;
  isLoading$(): Observable<boolean>;
}

const PendingRequestBarContext = createContext<IPendingRequestBarState | null>(null);

export const usePendingRequestBarContext = createUseContextHook(PendingRequestBarContext, "PendingRequestBarContext");

export const pendingRequestBarService: IPendingRequestBarState = {
  loadingIds: updatableBehaviorSubject<Set<string>>(new Set()),
  markLoading() {
    const id = uuid();
    this.loadingIds.update((value) => value.add(id));

    return () => {
      this.loadingIds.update((value) => {
        value.delete(id);
        return value;
      });
    };
  },
  isLoading$() {
    return this.loadingIds.pipe(map((loadingIds) => loadingIds.size > 0));
  },
};

/* -------------------------------------------------------------------------------------------------
 * PendingRequestBarProvider
 * -------------------------------------------------------------------------------------------------
 */

export const PendingRequestBarProvider: ComponentType<{}> = (props) => {
  const context = useConstant(() => pendingRequestBarService);

  return (
    <PendingRequestBarContext.Provider value={context}>
      <AppPendingRequestBar />
      {props.children}
    </PendingRequestBarContext.Provider>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const AppPendingRequestBar: ComponentType<{}> = () => {
  const context = usePendingRequestBarContext();
  const isLoading$ = useConstant(() => context.isLoading$());
  const isVisible = useObservableEagerState(isLoading$);
  const isOnline = useIsOnline();

  if (!isVisible || !isOnline) return null;

  return (
    <div className="fixed top-0 left-0 w-screen h-1 z-[9000]">
      <div className={loadingBar}>
        <div className="loaderBar"></div>
      </div>
    </div>
  );
};

const loadingBar = css`
  position: relative;
  padding: 3px;

  &:before {
    content: "";
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
  }

  .loaderBar {
    position: absolute;
    top: 0;
    right: 100%;
    bottom: 0;
    left: 0;
    background: ${red.red11};
    width: 0;
    animation: borealisBar 2s linear infinite;
  }

  @keyframes borealisBar {
    0% {
      left: 0%;
      right: 100%;
      width: 0%;
    }
    10% {
      left: 0%;
      right: 75%;
      width: 25%;
    }
    90% {
      right: 0%;
      left: 75%;
      width: 25%;
    }
    100% {
      left: 100%;
      right: 0%;
      width: 0%;
    }
  }
`;

/* -------------------------------------------------------------------------------------------------
 * PendingRequestBar
 * -------------------------------------------------------------------------------------------------
 */

/**
 * Returns any provided children but also displays the app's "loading bar"
 * at the top of the page.
 */
export const PendingRequestBar: ComponentType<{}> = (props) => {
  const context = usePendingRequestBarContext();

  useEffect(() => {
    return context.markLoading();
  }, [context]);

  return <>{props.children || null}</>;
};

/* -------------------------------------------------------------------------------------------------
 * withPendingRequestBar
 * -------------------------------------------------------------------------------------------------
 */

/**
 * This wraps a promise and turns on the pending request bar until
 * the promise resolves.
 */

export function withPendingRequestBar<T>(promise: Promise<T>): Promise<T>;
/**
 * This higher order function wraps an async function and, when called,
 * turns on the pending request bar until the promise resolves.
 */
export function withPendingRequestBar<T extends (...args: any[]) => Promise<any>>(fn: T): T;
export function withPendingRequestBar(fnOrPromise: ((...args: unknown[]) => Promise<unknown>) | Promise<unknown>) {
  if (fnOrPromise instanceof Promise) {
    const onComplete = pendingRequestBarService.markLoading();
    return fnOrPromise.finally(onComplete);
  }

  return (...args: unknown[]) => {
    const onComplete = pendingRequestBarService.markLoading();
    return fnOrPromise(...args).finally(onComplete);
  };
}

/* -------------------------------------------------------------------------------------------------
 * triggerPendingRequestBarOperator
 * -------------------------------------------------------------------------------------------------
 */

/**
 * An operator that triggers the pending request bar when the emission indicates
 * `isLoading === true`. Intended to be used with record loader observables.
 */
// Note that previously we used a hook to trigger the pending request bar but found that,
// in one specific instance, sometimes the hook wouldn't trigger cleanup and the pending
// request bar would stay on the screen indefinitely. It really *looked* like a react bug
// but I can't be sure. Regardless, this operator does the same thing but is more reliable.
export function triggerPendingRequestBarOperator<T extends [any, { isLoading: boolean }]>(
  label?: string,
): MonoTypeOperatorFunction<T> {
  return (source) => {
    const id = uuid();

    const markLoading = () => {
      pendingRequestBarService.loadingIds.update((value) => {
        // We only want to increment if the id is not already in the set
        if (!value.has(id) && label) {
          const count = pendingRequestBarState.get(label) ?? 0;
          pendingRequestBarState.set(label, count + 1);
        }

        return value.add(id);
      });
    };

    const removeLoading = () => {
      pendingRequestBarService.loadingIds.update((value) => {
        // We only want to decrement if the id is in the set
        if (value.has(id) && label) {
          const count = pendingRequestBarState.get(label) ?? 0;
          const newCount = count - 1;
          pendingRequestBarState.set(label, newCount);

          if (newCount < 1) {
            pendingRequestBarState.delete(label);
          }
        }

        value.delete(id);
        return value;
      });
    };

    return new Observable((subscriber) => {
      const sub = source.subscribe({
        next(value) {
          if (value[1].isLoading) {
            markLoading();
          } else {
            removeLoading();
          }

          subscriber.next(value);
        },
        error(error) {
          removeLoading();
          subscriber.error(error);
        },
        complete() {
          removeLoading();
          subscriber.complete();
        },
      });

      return () => {
        removeLoading();
        sub.unsubscribe();
      };
    });
  };
}

// We just track the state for debugging purposes (so that, if the pending bar won't go away,
// we can get an idea of what's causing it).
const pendingRequestBarState = new Map<string, number>();

// Added to global state for debugging purposes in the console
(globalThis as any).pendingRequestBarState = pendingRequestBarState;

/* -----------------------------------------------------------------------------------------------*/
