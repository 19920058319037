import { UnreachableCaseError } from "libs/errors";
import {
  DraftAttachmentDoc,
  DraftRecipientDoc,
  DraftGroupRecipientDoc,
  DraftUserRecipientDoc,
  generateRecordId,
  getMapRecords,
  getPointer,
  RecordValue,
  ThreadVisibility,
  ThreadTimelineTypeEnum,
  createRecordMapFromPointersWithRecords,
} from "libs/schema";
import { applyOperation, getOperationPointers, op, Transaction } from "libs/transaction";
import { IEditorMention } from "~/components/forms/message-editor";
import { IRecipientOption } from "~/components/forms/ThreadRecipients";
import { toast } from "~/environment/toast-service";
import * as ops from "libs/actions";
import { isEqual } from "libs/predicates";
import { cloneDeep, isString, uniqBy, uniqWith } from "lodash-es";
import { ClientRecordLoaderApi, GetOptions } from "~/environment/RecordLoader";
import { withTransaction, write } from "./write";
import dayjs from "dayjs";

export type DraftRecipient = { type: IRecipientOption["type"]; id: string };

export function mapRecipientOptionToDraftRecipient(option: IRecipientOption): DraftRecipient {
  return { type: option.type, id: option.value };
}

/**
 * Updates a draft. If the draft is ready to be sent (scheduled_to_be_sent_at is set), it will be sent.
 */
export const mergeDraft = withTransaction(
  "mergeDraft",
  async (
    environment,
    transaction,
    props: MergeDraftProps & {
      scheduledToBeSentAt?: Date;
      shouldResendNotifications?: boolean;
      afterUndo?: () => void;
      noToast?: boolean;
    },
  ) => {
    const { recordLoader, logger } = environment;

    applyMergeDraftToTx(transaction, props);

    if (props.scheduledToBeSentAt) {
      const pointers = uniqWith(transaction.operations.flatMap(getOperationPointers), isEqual);

      const [pointersWithRecord] = await recordLoader.getRecords(pointers);

      const beforeRecordMap = createRecordMapFromPointersWithRecords(pointersWithRecord);

      const afterRecordMap = cloneDeep(beforeRecordMap);

      const currentTimestamp = new Date().toISOString();

      // Apply the mutations.
      for (const operation of transaction.operations) {
        applyOperation({
          recordMap: afterRecordMap,
          operation,
          authorId: transaction.authorId,
          currentTimestamp,
          isServer: false,
        });
      }

      const [draft] = getMapRecords(afterRecordMap, "draft");

      if (!draft) {
        logger.error(`[mergeDraft] ${props.draftId} not found after merge.`);
        throw new Error(`[mergeDraft] draft not found.`);
      }

      if (draft.is_edit) {
        logger.notice(`[mergeDraft] [edit] sending draft ${draft.id} ...`);

        const [message] = await recordLoader.getRecord(getPointer("message", draft.id));

        if (!message) {
          logger.error(`[mergeDraft] [edit] message not find ${draft.id}`);
          alert(`Count not find message to edit`);
          throw new Error(`[mergeDraft] [edit] message not found.`);
        }

        ops.applyOperationsToTransaction(
          transaction,
          ops.draft.applyEditsToMessage({
            draft,
            message,
            shouldResendNotifications: !!props.shouldResendNotifications,
          }),
        );
      } else if (draft.is_reply) {
        logger.notice(`[mergeDraft] [reply] sending draft ${draft.id} ...`);

        const [thread] = await recordLoader.getRecord(getPointer("thread", draft.thread_id));

        if (!thread) {
          logger.error(`[mergeDraft] [reply] thread not found ${draft.thread_id}`);

          throw new Error(`[mergeDraft] [reply] thread not found.`);
        }

        ops.applyOperationsToTransaction(
          transaction,
          ops.draft.sendDraftReply({
            scheduledToBeSentAt: props.scheduledToBeSentAt,
            draft,
            thread,
          }),
        );
      } else {
        logger.notice(`[mergeDraft] [new] sending draft ${draft.id} ...`);

        const branchedFromMessage =
          draft.branched_from_message_id ?
            await recordLoader.getRecord(getPointer("message", draft.branched_from_message_id)).then(([m]) => m)
          : undefined;

        ops.applyOperationsToTransaction(
          transaction,
          ops.draft.sendNewThreadDraft({
            scheduledToBeSentAt: props.scheduledToBeSentAt,
            draft,
            branchedFromMessage: branchedFromMessage || undefined,
          }),
        );
      }

      const branchedDraftTimelineEntry =
        draft.branched_from_thread_id ?
          await recordLoader
            .getRecord(
              getPointer("thread_timeline", {
                entry_id: draft.id,
                thread_id: draft.branched_from_thread_id,
              }),
            )
            .then(([m]) => m)
        : undefined;

      applyDeleteDraftToTx(transaction, {
        draft,
        branchedDraftTimelineEntry,
      });
    }

    await write(environment, {
      transaction,
      canUndo:
        !!props.scheduledToBeSentAt && props.shouldResendNotifications === undefined ?
          () => dayjs().isBefore(props.scheduledToBeSentAt)
        : false,
      onOptimisticUndo: () => {
        toast("vanilla", {
          subject: "Unsending draft...",
        });

        props.afterUndo?.();
      },
      onServerUndo: () => {
        toast("vanilla", {
          subject: "Unsending draft...Done!",
        });
      },
    });
  },
);

/* -------------------------------------------------------------------------------------------------
 *  deleteDraft
 * -------------------------------------------------------------------------------------------------
 */

export const deleteDraft = withTransaction(
  "deleteDraft",
  async (
    environment,
    transaction,
    props: {
      currentUserId: string;
      draftId: string;
      afterUndo?: () => void;
    },
  ) => {
    environment.logger.notice(`[deleteDraft] deleting draft ${props.draftId} ...`);

    const loadedData = await loadApplyDeleteDraftToTxData({
      recordLoader: environment.recordLoader,
      draftId: props.draftId,
    });

    if (isString(loadedData)) {
      environment.logger.error(loadedData);
      return;
    }

    applyDeleteDraftToTx(transaction, loadedData);

    try {
      await write(environment, {
        transaction,
        onOptimisticUndo: () => {
          toast("vanilla", {
            subject: "Draft deleted.",
          });
        },
      });
    } catch (error) {
      toast("vanilla", {
        subject: "Failed to delete draft.",
      });

      throw error;
    }
  },
);

/* -----------------------------------------------------------------------------------------------*/

export interface MergeDraftProps {
  type: "COMMS" | "EMAIL";
  draftId: string;
  currentUserId: string;
  ownerOrganizationId: string;
  is_reply: boolean;
  is_edit: boolean;
  threadId: string;
  branchedFrom?: null | {
    threadId: string;
    messageId: string;
    messageTimelineOrder: string;
  };
  subject?: string | null;
  bodyHTML?: string;
  to?: DraftRecipient[];
  cc?: DraftRecipient[];
  bcc?: DraftRecipient[];
  visibility?: ThreadVisibility | null;
  userMentions?: IEditorMention[];
  groupMentions?: IEditorMention[];
  attachments?: DraftAttachmentDoc[];
}

function applyMergeDraftToTx(transaction: Transaction, props: MergeDraftProps) {
  switch (props.type) {
    case "COMMS": {
      if (props.cc && props.cc.length > 0) {
        alert("Draft cc recipients not supported yet.");
        throw new Error("applyDraftUpdateToTx: Not implemented");
      } else if (props.bcc && props.bcc.length > 0) {
        alert("Draft bcc recipients not supported yet.");
        throw new Error("applyDraftUpdateToTx: Not implemented");
      }

      ops.applyOperationsToTransaction(
        transaction,
        ops.draft.mergeDraft({
          draftId: props.draftId,
          userId: props.currentUserId,
          type: props.type,
          is_edit: props.is_edit,
          is_reply: props.is_reply,
          ownerOrganizationId: props.ownerOrganizationId,
          threadId: props.threadId,
          attachments: props.attachments,
          bodyHTML: props.bodyHTML,
          branchedFrom: props.branchedFrom,
          subject: props.subject,
          visibility: props.visibility,
          to: getToRecipients(props),
        }),
      );

      break;
    }
    case "EMAIL": {
      throw new Error("Email drafts are not yet supported.");
    }
    default: {
      throw new UnreachableCaseError(props.type);
    }
  }
}

export function getToRecipients(props: Pick<MergeDraftProps, "to" | "userMentions" | "groupMentions">) {
  const { userMentions = [], groupMentions = [], to = [] } = props;

  // Note that drafts for replies only add recipients that are *new* to the thread to the `to`
  // field. If someone is mentioned in a reply who is already participating in the thread, they
  // will not be added to the `to` field. Because of this, we need to ensure that all mentioned
  // users and groups are included as recipients when constructing the message.
  const toRecipientOptions = uniqBy(
    [
      ...userMentions.map((r) => ({ ...r, is_mentioned: true })),
      ...groupMentions.map((r) => ({ ...r, is_mentioned: true })),
      ...to.map((r) => ({ ...r, is_mentioned: false, priority: 300 })),
    ],
    (r) => r.id,
  );

  return toRecipientOptions.map((recipient) => {
    switch (recipient.type) {
      case "user": {
        return {
          id: generateRecordId("draft_user_recipient_doc", {
            type: "USER",
            user_id: recipient.id,
          }),
          type: "USER",
          user_id: recipient.id,
          priority: recipient.priority,
          is_implicit: false,
          is_mentioned: recipient.is_mentioned,
        } satisfies DraftUserRecipientDoc;
      }
      case "group": {
        return {
          id: generateRecordId("draft_group_recipient_doc", {
            type: "GROUP",
            group_id: recipient.id,
          }),
          type: "GROUP",
          group_id: recipient.id,
          priority: recipient.priority,
          is_implicit: false,
          is_mentioned: recipient.is_mentioned,
        } satisfies DraftGroupRecipientDoc;
      }
      case "email": {
        throw new Error("Email drafts are not yet supported.");
      }
      default: {
        throw new UnreachableCaseError(recipient.type);
      }
    }
  });
}

/* -----------------------------------------------------------------------------------------------*/

interface ApplyDeleteDraftProps {
  draft: { id: string };
  branchedDraftTimelineEntry?: { id: string } | null;
}

function applyDeleteDraftToTx(transaction: Transaction, props: ApplyDeleteDraftProps) {
  const { draft } = props;

  transaction.operations.push(op.delete("draft", draft));

  if (props.branchedDraftTimelineEntry) {
    transaction.operations.push(op.delete("thread_timeline", props.branchedDraftTimelineEntry));
  }
}

async function loadApplyDeleteDraftToTxData(
  props: {
    recordLoader: ClientRecordLoaderApi;
    draftId: string;
  },
  options?: GetOptions,
): Promise<ApplyDeleteDraftProps | string> {
  const { recordLoader, draftId } = props;

  const [[draft]] = await Promise.all([
    recordLoader.getRecord(
      {
        table: "draft",
        id: draftId,
      },
      options,
    ),
  ]);

  if (!draft) {
    return `Draft with ID ${draftId} not found.`;
  }

  let branchedDraftTimelineEntry: RecordValue<"thread_timeline"> | undefined;

  if (draft.branched_from_thread_id) {
    const [record] = await recordLoader.getRecord(
      getPointer("thread_timeline", {
        entry_id: draftId,
        thread_id: draft.branched_from_thread_id,
      }),
      options,
    );

    if (record) {
      branchedDraftTimelineEntry = record;
    }
  }

  return {
    draft: draft,
    branchedDraftTimelineEntry,
  };
}
